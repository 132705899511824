import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Company, Recruiter } from '@ats/models';
import { FeaturesRoutingEnum } from '@features/features-routing.enum';
import { Store } from '@ngxs/store';
import { Logout } from '@store/session/session.actions';

@Component({
  selector: 'app-profile-popup',
  templateUrl: './profile-popup.component.html',
  styleUrls: ['./profile-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfilePopupComponent {
  @Input() recruiter: Recruiter;
  @Input() company: Company;

  featuresRoutingEnum = FeaturesRoutingEnum;
  show = false;

  constructor(private readonly store: Store) {}

  doLogout(_: any) {
    this.store.dispatch(new Logout());
  }
}
