var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import { isPlatformBrowser } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { GlobalService } from '@core/services/global.service';
import { Action, Selector, State } from '@ngxs/store';
import { SetAuthenticatedRecruiter } from '@store/recruiters/recruiters.actions';
import { Init, InitUser, Logout, RecoverPassword, RecoverPasswordFailed, RecoverPasswordSuccess, RefreshTokensStore, RemoveTokens, SetTokens, SignIn, SignInFailed, SignInSuccess, SignUp, SignUpFailed, SignUpWizbii, UpdateUser, } from '@store/session/session.actions';
import { AccountApiWebservice } from '@webservices/account-api/account-api.webservice';
import { DataStorageService, deserializeJwt } from '@wizbii/angular-utilities';
import { AuthenticationWebservice } from '@wizbii/webservices';
import { CookieService } from 'ngx-cookie-service';
import { Observable, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
export var SessionStateEnum;
(function (SessionStateEnum) {
    SessionStateEnum["Init"] = "INIT";
    SessionStateEnum["Logged"] = "LOGGED";
    SessionStateEnum["LoggingIn"] = "LOGGING_IN";
    SessionStateEnum["SigningUpIn"] = "SIGNING_UP_IN";
    SessionStateEnum["Recovering"] = "RECOVERING";
    SessionStateEnum["Recovered"] = "RECOVERED";
    SessionStateEnum["NotLogged"] = "NOT_LOGGED";
})(SessionStateEnum || (SessionStateEnum = {}));
var SessionStateModel = /** @class */ (function () {
    function SessionStateModel() {
    }
    return SessionStateModel;
}());
export { SessionStateModel };
export var SessionErrorMessage;
(function (SessionErrorMessage) {
    SessionErrorMessage["badCredential"] = "BadCredentialsException";
    SessionErrorMessage["emailAlreadyUsed"] = "EmailAlreadyUsedException";
    SessionErrorMessage["invitationAlreadyExist"] = "InvitationAlreadyExist";
    SessionErrorMessage["recruiterAlreadyExists"] = "RecruiterAlreadyExists";
})(SessionErrorMessage || (SessionErrorMessage = {}));
var defaultState = {
    tokens: null,
    loading: false,
    state: SessionStateEnum.Init,
    error: null,
    errorMessage: null,
    user: null,
};
var SessionState = /** @class */ (function () {
    function SessionState(dataStorageService, authService, accountApiWebservice, cookieService, globalService, platformId, document) {
        this.dataStorageService = dataStorageService;
        this.authService = authService;
        this.accountApiWebservice = accountApiWebservice;
        this.cookieService = cookieService;
        this.globalService = globalService;
        this.platformId = platformId;
        this.document = document;
    }
    // tslint:disable:no-identical-functions
    SessionState.tokens = function (state) {
        return state.tokens;
    };
    SessionState.hasTokens = function (state) {
        return !!state.tokens;
    };
    SessionState.user = function (state) {
        return state.user;
    };
    SessionState.state = function (state) {
        return state.state;
    };
    SessionState.isInitialized = function (state) {
        return state.state !== SessionStateEnum.Init;
    };
    SessionState.isLogged = function (state) {
        return state.state === SessionStateEnum.Logged;
    };
    SessionState.failed = function (state) {
        return state.state === SessionStateEnum.NotLogged;
    };
    SessionState.recovered = function (state) {
        return state.state === SessionStateEnum.Recovered;
    };
    SessionState.loading = function (state) {
        return state.loading;
    };
    SessionState.error = function (state) {
        return state.error;
    };
    SessionState.errorMessage = function (state) {
        return state.errorMessage;
    };
    SessionState.prototype.init = function (ctx, _a) {
        var tokens = _a.tokens;
        var realTokens = tokens ? tokens : this.readTokens();
        if (!!realTokens) {
            this.globalService.init(realTokens.token, realTokens.refreshToken);
            ctx.patchState({ tokens: realTokens });
            var userId = deserializeJwt(realTokens.token)['user-id'];
            return ctx.dispatch([new InitUser(userId), new SetAuthenticatedRecruiter(userId)]).pipe(switchMap(function () { return of(ctx.patchState({ state: SessionStateEnum.Logged })); }), catchError(function () { return of(ctx.patchState({ state: SessionStateEnum.NotLogged })); }));
        }
        return ctx.patchState({ state: SessionStateEnum.NotLogged });
    };
    SessionState.prototype.initUser = function (ctx, _a) {
        var id = _a.id;
        return this.authService.getUserOverview(id).pipe(switchMap(function (user) { return of(ctx.patchState({ user: user })); }));
    };
    SessionState.prototype.updateUser = function (ctx, _a) {
        var user = _a.user;
        return ctx.patchState({ user: user });
    };
    SessionState.prototype.refreshTokensStore = function (ctx, action) {
        var tokens = action.tokens;
        return ctx.patchState({
            tokens: tokens,
        });
    };
    SessionState.prototype.signIn = function (ctx, _a) {
        var username = _a.username, password = _a.password;
        // Prevent parallel requests
        if (this.isLoading(ctx)) {
            return of(undefined);
        }
        ctx.patchState({ error: null, errorMessage: null, loading: true, state: SessionStateEnum.LoggingIn });
        return this.authService.signIn(username, password, { headers: { ignoreLoadingBar: '' } }).pipe(switchMap(function (tokens) { return ctx.dispatch(new SignInSuccess(tokens)); }), catchError(function (error) { return ctx.dispatch(new SignInFailed(error)); }));
    };
    SessionState.prototype.signInSuccess = function (ctx, _a) {
        var tokens = _a.tokens;
        this.writeTokens(tokens);
        ctx.patchState({
            loading: false,
            state: SessionStateEnum.Logged,
            tokens: tokens,
        });
        return ctx.dispatch(new Init(tokens));
    };
    SessionState.prototype.signInFailed = function (ctx, _a) {
        var error = _a.error;
        this.forgetTokens();
        // Retrieve best error message (note that `HTTPErrorResponse` inherits from `Error`)
        var errorMessage = (error instanceof HttpErrorResponse && error.error && error.error.type) || error.message;
        ctx.patchState({
            tokens: null,
            state: SessionStateEnum.NotLogged,
            loading: false,
            error: error,
            errorMessage: errorMessage,
        });
        if (errorMessage === SessionErrorMessage.badCredential) {
            console.warn(errorMessage);
            return of(undefined);
        }
        return throwError(error); // re-throw unhandled errors
    };
    SessionState.prototype.signup = function (ctx, _a) {
        var firstname = _a.firstname, lastname = _a.lastname, company = _a.company, email = _a.email, password = _a.password, phone = _a.phone, invitationToken = _a.invitationToken;
        // Prevent parallel requests
        if (this.isLoading(ctx)) {
            return of(undefined);
        }
        ctx.patchState({ error: null, errorMessage: null, loading: true, state: SessionStateEnum.SigningUpIn });
        return this.accountApiWebservice
            .create({
            email: email,
            firstName: firstname,
            lastName: lastname,
            phone: phone,
        }, {
            name: company,
        }, {
            username: email,
            firstName: firstname,
            lastName: lastname,
            password: password,
            phone: phone,
        }, invitationToken)
            .pipe(switchMap(function (tokens) { return ctx.dispatch(new SignInSuccess(tokens)); }), catchError(function (error) { return ctx.dispatch(new SignUpFailed(error)); }));
    };
    SessionState.prototype.signupWizbii = function (ctx, _a) {
        var email = _a.email, firstname = _a.firstname, lastname = _a.lastname, company = _a.company, phone = _a.phone, invitationToken = _a.invitationToken;
        // Prevent parallel requests
        if (this.isLoading(ctx)) {
            return of(undefined);
        }
        ctx.patchState({ error: null, errorMessage: null, loading: true, state: SessionStateEnum.SigningUpIn });
        return this.accountApiWebservice
            .create({
            email: email,
            firstName: firstname,
            lastName: lastname,
            phone: phone,
        }, {
            name: company,
        }, null, invitationToken)
            .pipe(switchMap(function (tokens) { return ctx.dispatch(new SignInSuccess(tokens)); }), catchError(function (error) { return ctx.dispatch(new SignUpFailed(error)); }));
    };
    SessionState.prototype.failed = function (ctx, action) {
        var error = action.error;
        var errorMessage = (error instanceof HttpErrorResponse && error.error && error.error.type) || error.message;
        this.forgetTokens();
        return ctx.patchState({
            error: error,
            errorMessage: errorMessage,
            tokens: null,
            state: SessionStateEnum.NotLogged,
            loading: false,
        });
    };
    SessionState.prototype.recoverPassword = function (ctx, action) {
        var email = action.email;
        ctx.patchState({ error: null, errorMessage: null, loading: true, state: SessionStateEnum.Recovering });
        return this.authService.getRecoveryEmail(email, { headers: { ignoreLoadingBar: '' } }).pipe(switchMap(function () { return ctx.dispatch(new RecoverPasswordSuccess()); }), catchError(function (error) { return ctx.dispatch(new RecoverPasswordFailed(error)); }));
    };
    SessionState.prototype.recoverPasswordSuccess = function (ctx) {
        return ctx.patchState({
            loading: false,
            state: SessionStateEnum.Recovered,
        });
    };
    SessionState.prototype.recoverPasswordFailed = function (ctx, action) {
        var error = action.error;
        var errorMessage = (error instanceof HttpErrorResponse && error.error && error.error.type) || error.message;
        this.forgetTokens();
        return ctx.patchState({
            error: error,
            errorMessage: errorMessage,
            tokens: null,
            state: SessionStateEnum.NotLogged,
            loading: false,
        });
    };
    SessionState.prototype.setTokens = function (ctx, action) {
        var tokens = action.tokens;
        this.writeTokens(tokens);
        return ctx.patchState({
            tokens: tokens,
        });
    };
    SessionState.prototype.removeTokens = function (ctx) {
        this.forgetTokens();
        return ctx.patchState({
            tokens: null,
        });
    };
    SessionState.prototype.logout = function (ctx) {
        this.forgetTokens();
        if (isPlatformBrowser(this.platformId)) {
            this.document.location = this.document.location.origin;
        }
        return ctx.setState(defaultState);
    };
    SessionState.prototype.isLoading = function (ctx) {
        return ctx.getState().loading;
    };
    SessionState.prototype.readTokens = function () {
        var rawTokens = JSON.parse(this.cookieService.get(GlobalService.TOKEN_KEY) || 'null');
        return !!rawTokens ? rawTokens : null;
    };
    SessionState.prototype.writeTokens = function (tokens) {
        var cookieDomain = this.getCookieDomain();
        var expiryExists = this.cookieService.check(GlobalService.EXPIRY_KEY);
        var msIn390Days = 1000 * 3600 * 24 * 390;
        var expiry = expiryExists
            ? new Date(this.cookieService.get(GlobalService.EXPIRY_KEY))
            : new Date(Date.now() + msIn390Days);
        if (!expiryExists) {
            this.cookieService.set(GlobalService.EXPIRY_KEY, expiry.getTime().toString(), expiry, '/', cookieDomain, cookieDomain !== 'localhost', cookieDomain === 'localhost' ? 'Lax' : 'None');
        }
        this.cookieService.set(GlobalService.TOKEN_KEY, JSON.stringify(tokens), expiry, '/', cookieDomain, cookieDomain !== 'localhost', cookieDomain === 'localhost' ? 'Lax' : 'None');
    };
    SessionState.prototype.forgetTokens = function () {
        var cookieDomain = this.getCookieDomain();
        this.dataStorageService.remove(GlobalService.TOKEN_KEY);
        this.cookieService.set(GlobalService.TOKEN_KEY, '', new Date('Thu, 01 Jan 1970 00:00:01 GMT'), '/', cookieDomain, cookieDomain !== 'localhost', cookieDomain === 'localhost' ? 'Lax' : 'None');
        this.cookieService.set(GlobalService.EXPIRY_KEY, '', new Date('Thu, 01 Jan 1970 00:00:01 GMT'), '/', cookieDomain, cookieDomain !== 'localhost', cookieDomain === 'localhost' ? 'Lax' : 'None');
    };
    SessionState.prototype.getCookieDomain = function () {
        var cookieSubDomain = __spread([''], this.document.location.hostname.split('.').slice(-2)).join('.');
        return cookieSubDomain === '.localhost' ? 'localhost' : cookieSubDomain;
    };
    __decorate([
        Action(Init),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, Init]),
        __metadata("design:returntype", void 0)
    ], SessionState.prototype, "init", null);
    __decorate([
        Action(InitUser),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, InitUser]),
        __metadata("design:returntype", void 0)
    ], SessionState.prototype, "initUser", null);
    __decorate([
        Action(UpdateUser),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, UpdateUser]),
        __metadata("design:returntype", void 0)
    ], SessionState.prototype, "updateUser", null);
    __decorate([
        Action(RefreshTokensStore),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, RefreshTokensStore]),
        __metadata("design:returntype", void 0)
    ], SessionState.prototype, "refreshTokensStore", null);
    __decorate([
        Action(SignIn),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, SignIn]),
        __metadata("design:returntype", Observable)
    ], SessionState.prototype, "signIn", null);
    __decorate([
        Action(SignInSuccess),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, SignInSuccess]),
        __metadata("design:returntype", Observable)
    ], SessionState.prototype, "signInSuccess", null);
    __decorate([
        Action(SignInFailed),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, SignInFailed]),
        __metadata("design:returntype", Observable)
    ], SessionState.prototype, "signInFailed", null);
    __decorate([
        Action(SignUp),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, SignUp]),
        __metadata("design:returntype", void 0)
    ], SessionState.prototype, "signup", null);
    __decorate([
        Action(SignUpWizbii),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, SignUpWizbii]),
        __metadata("design:returntype", void 0)
    ], SessionState.prototype, "signupWizbii", null);
    __decorate([
        Action(SignUpFailed),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, SignUpFailed]),
        __metadata("design:returntype", void 0)
    ], SessionState.prototype, "failed", null);
    __decorate([
        Action(RecoverPassword),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, RecoverPassword]),
        __metadata("design:returntype", void 0)
    ], SessionState.prototype, "recoverPassword", null);
    __decorate([
        Action(RecoverPasswordSuccess),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], SessionState.prototype, "recoverPasswordSuccess", null);
    __decorate([
        Action(RecoverPasswordFailed),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, RecoverPasswordFailed]),
        __metadata("design:returntype", void 0)
    ], SessionState.prototype, "recoverPasswordFailed", null);
    __decorate([
        Action(SetTokens),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, SetTokens]),
        __metadata("design:returntype", void 0)
    ], SessionState.prototype, "setTokens", null);
    __decorate([
        Action(RemoveTokens),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], SessionState.prototype, "removeTokens", null);
    __decorate([
        Action(Logout),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], SessionState.prototype, "logout", null);
    __decorate([
        Selector(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [SessionStateModel]),
        __metadata("design:returntype", void 0)
    ], SessionState, "tokens", null);
    __decorate([
        Selector(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [SessionStateModel]),
        __metadata("design:returntype", void 0)
    ], SessionState, "hasTokens", null);
    __decorate([
        Selector(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [SessionStateModel]),
        __metadata("design:returntype", void 0)
    ], SessionState, "user", null);
    __decorate([
        Selector(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [SessionStateModel]),
        __metadata("design:returntype", void 0)
    ], SessionState, "state", null);
    __decorate([
        Selector(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [SessionStateModel]),
        __metadata("design:returntype", void 0)
    ], SessionState, "isInitialized", null);
    __decorate([
        Selector(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [SessionStateModel]),
        __metadata("design:returntype", void 0)
    ], SessionState, "isLogged", null);
    __decorate([
        Selector(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [SessionStateModel]),
        __metadata("design:returntype", void 0)
    ], SessionState, "failed", null);
    __decorate([
        Selector(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [SessionStateModel]),
        __metadata("design:returntype", void 0)
    ], SessionState, "recovered", null);
    __decorate([
        Selector(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [SessionStateModel]),
        __metadata("design:returntype", void 0)
    ], SessionState, "loading", null);
    __decorate([
        Selector(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [SessionStateModel]),
        __metadata("design:returntype", void 0)
    ], SessionState, "error", null);
    __decorate([
        Selector(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [SessionStateModel]),
        __metadata("design:returntype", void 0)
    ], SessionState, "errorMessage", null);
    SessionState = __decorate([
        State({
            name: 'session',
            defaults: defaultState,
        }),
        __metadata("design:paramtypes", [DataStorageService,
            AuthenticationWebservice,
            AccountApiWebservice,
            CookieService,
            GlobalService,
            Object, Object])
    ], SessionState);
    return SessionState;
}());
export { SessionState };
