<header class="header">
  <div class="header__company company">
    <a
      appEventTracker
      eventCategory="App Navigation"
      eventAction="Click Logo"
      class="btn-icon btn-clean"
      [routerLink]="['/', featuresRoutingEnum.Jobs]"
    >
      <svg-icon icon="atsii" label="Atsii" width="5.0625em" height="1.875em"></svg-icon>
    </a>
  </div>

  <app-profile-popup *ngIf="recruiter && company" [recruiter]="recruiter" [company]="company"></app-profile-popup>
</header>
