import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ProfilePopupComponent } from '@commons/header/profile-popup/profile-popup.component';
import { LogoModule } from '@commons/logo/logo.module';
import { PopupModule } from '@commons/popup/popup.module';
import { SvgIconModule } from '@commons/svg-icon/svg-icon.module';
import { TrackerModule } from '@commons/tracker/tracker.module';

@NgModule({
  declarations: [ProfilePopupComponent],
  imports: [CommonModule, LogoModule, RouterModule, PopupModule, SvgIconModule, TrackerModule],
  exports: [ProfilePopupComponent],
})
export class HeaderModule {}
