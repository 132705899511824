import { environment } from '@env/environment';
import { ImageService } from '@wizbii/angular-utilities';
var LogoComponent = /** @class */ (function () {
    function LogoComponent(window, imageService) {
        this.window = window;
        this.imageService = imageService;
        this.background = '#8782ff';
        this.size = 'medium';
        this.ariaHidden = true;
        this.backgroundColor = '#fff';
        this.color = '#ffffff';
        this.backgroundImage = '';
    }
    Object.defineProperty(LogoComponent.prototype, "item", {
        set: function (item) {
            if (item.logoUrl) {
                this.imageSize = this.computeImageSize();
                var logoUrl = this.getLogoUrl(item);
                var resizedLogoUrl = this.imageService.resize(logoUrl, { width: this.imageSize });
                this.backgroundImage = "url(" + resizedLogoUrl + ")";
                this.backgroundColor = '#fff';
                this.text = undefined;
            }
            else {
                this.backgroundImage = undefined;
                this.backgroundColor = this.background;
                this.text = "" + (item.firstName ? item.firstName[0] : '-') + (item.lastName ? item.lastName[0] : '-');
                this.fallback = true;
            }
        },
        enumerable: true,
        configurable: true
    });
    LogoComponent.prototype.getLogoUrl = function (item) {
        return item.logoUrl.startsWith('http')
            ? item.logoUrl
            : environment.api.googleStorage + "/" + environment.wizbiiFiles + "/" + item.logoUrl;
    };
    LogoComponent.prototype.computeImageSize = function () {
        var size = this.size === 'medium' ? 80 : 110;
        if (this.window.devicePixelRatio >= 2) {
            size = size * 2;
        }
        return size;
    };
    return LogoComponent;
}());
export { LogoComponent };
