var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import { toRecruiter } from '@ats/models';
import { RecruiterWebservice } from '@ats/webservices';
import { NotifStatus } from '@commons/notifications/notif-status.enum';
import { NotificationsService } from '@commons/notifications/notifications.service';
import { FeaturesRoutingEnum } from '@features/features-routing.enum';
import { Navigate } from '@ngxs/router-plugin';
import { Action, createSelector, Selector, State, Store } from '@ngxs/store';
import { patch, updateItem } from '@ngxs/store/operators';
import { SetAuthenticatedCompany } from '@store/companies/companies.actions';
import { AddAuthorizations, DeleteAuthorizations, FindRecruitersByCompanyId, GetRecruiterById, GetRecruiterByUserId, GetRecruiterSuccess, HandleErrors, SetAuthenticatedRecruiter, SetRecruiter, } from '@store/recruiters/recruiters.actions';
import { SessionState } from '@store/session/session.state';
import { of } from 'rxjs';
import { catchError, filter, first, map, switchMap } from 'rxjs/operators';
var RecruitersStateModel = /** @class */ (function () {
    function RecruitersStateModel() {
    }
    return RecruitersStateModel;
}());
export { RecruitersStateModel };
var defaultState = {
    authenticatedRecruiterId: null,
    recruiters: {},
    recruiterList: null,
    error: null,
};
export var recruiterRefusedText = 'Recruiter refused';
var RecruitersState = /** @class */ (function () {
    function RecruitersState(recruiterWebservice, store, notifService) {
        this.recruiterWebservice = recruiterWebservice;
        this.store = store;
        this.notifService = notifService;
    }
    RecruitersState_1 = RecruitersState;
    RecruitersState.recruiterById = function (recruiterId) {
        return createSelector([RecruitersState_1], function (state) {
            return state.recruiters[recruiterId];
        });
    };
    RecruitersState.recruiterByUserId = function (userId) {
        return createSelector([RecruitersState_1], function (state) {
            return Object.values(state.recruiters).find(function (recruiter) { return recruiter.userId === userId; });
        });
    };
    RecruitersState.authenticatedRecruiter = function (state) {
        return state.recruiters[state.authenticatedRecruiterId];
    };
    RecruitersState.authenticatedRecruiterId = function (state) {
        return state.authenticatedRecruiterId;
    };
    RecruitersState.list = function (state) {
        return state.recruiterList;
    };
    RecruitersState.listLength = function (state) {
        return (state.recruiterList || []).length;
    };
    RecruitersState.prototype.getRecruiterByUserId = function (ctx, _a) {
        var id = _a.id;
        var recruiters = ctx.getState().recruiters;
        if (!recruiters[id]) {
            return this.recruiterWebservice.getByUserId(id).pipe(switchMap(function (profile) { return ctx.dispatch(new GetRecruiterSuccess(profile)); }), catchError(function (error) { return ctx.dispatch(new HandleErrors(error)); }));
        }
    };
    RecruitersState.prototype.getRecruiterById = function (ctx, _a) {
        var id = _a.id;
        var recruiters = ctx.getState().recruiters;
        if (!recruiters[id]) {
            return this.recruiterWebservice.getRecruiterById(id).pipe(switchMap(function (profile) { return ctx.dispatch(new GetRecruiterSuccess(profile)); }), catchError(function (error) { return ctx.dispatch(new HandleErrors(error)); }));
        }
    };
    RecruitersState.prototype.findRecruitersByCompanyId = function (ctx) {
        var _this = this;
        var _a = ctx.getState(), recruiters = _a.recruiters, authenticatedRecruiterId = _a.authenticatedRecruiterId;
        return this.recruiterWebservice.findRecruitersByCompanyId(recruiters[authenticatedRecruiterId].companyId).pipe(map(function (recruiterList) {
            ctx.patchState({
                recruiterList: recruiterList,
            });
            return recruiterList;
        }), catchError(function (error) {
            _this.notifService.createToast('Une erreur est survenue lors de la récupération des collaborateurs.', NotifStatus.Failed);
            return ctx.dispatch(new HandleErrors(error));
        }));
    };
    RecruitersState.prototype.addAuthorizations = function (ctx, _a) {
        var recruiter = _a.recruiter, authorizations = _a.authorizations;
        return ctx.setState(patch({
            recruiterList: updateItem(function (rec) { return rec._id === recruiter._id; }, toRecruiter(__assign({}, recruiter, { authorizations: __spread(recruiter.authorizations, authorizations) }))),
        }));
    };
    RecruitersState.prototype.deleteAuthorizations = function (ctx, _a) {
        var recruiter = _a.recruiter, authorizations = _a.authorizations;
        return ctx.setState(patch({
            recruiterList: updateItem(function (rec) { return rec._id === recruiter._id; }, toRecruiter(__assign({}, recruiter, { authorizations: recruiter.authorizations.filter(function (authorization) { return !authorizations.includes(authorization); }) }))),
        }));
    };
    RecruitersState.prototype.getRecruiterSuccess = function (ctx, _a) {
        var _b;
        var recruiter = _a.recruiter;
        return ctx.setState(patch({
            authenticatedRecruiterId: recruiter._id,
            recruiters: patch((_b = {},
                _b[recruiter._id] = recruiter,
                _b)),
        }));
    };
    RecruitersState.prototype.setAuthenticatedRecruiter = function (ctx, _a) {
        var _this = this;
        var id = _a.id;
        return ctx.dispatch(new GetRecruiterByUserId(id)).pipe(switchMap(function () { return _this.store.select(RecruitersState_1.authenticatedRecruiter); }), filter(function (recruiter) { return !!recruiter; }), first(), switchMap(function (recruiter) { return ctx.dispatch(new SetAuthenticatedCompany(recruiter.companyId)); }));
    };
    RecruitersState.prototype.setApplication = function (ctx, _a) {
        var _b;
        var recruiter = _a.recruiter;
        return ctx.setState(patch({
            recruiters: patch((_b = {},
                _b[recruiter._id] = recruiter,
                _b)),
        }));
    };
    RecruitersState.prototype.handleErrors = function (ctx, _a) {
        var error = _a.error;
        // tslint:disable no-small-switch
        switch (error.status) {
            // The Job doesn't exist so => 404
            case 404: {
                console.error('Not found');
                if (this.store.selectSnapshot(SessionState.hasTokens)) {
                    return of();
                }
                ctx.patchState({ error: error });
                return ctx.dispatch(new Navigate(['/', FeaturesRoutingEnum.NotFound], undefined, { skipLocationChange: true }));
            }
            default: {
                console.error("Code " + error.status + " => " + error.statusText);
                return ctx.patchState({ error: error });
            }
        }
        // tslint:enable no-small-switch
    };
    var RecruitersState_1;
    __decorate([
        Action(GetRecruiterByUserId),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, GetRecruiterByUserId]),
        __metadata("design:returntype", void 0)
    ], RecruitersState.prototype, "getRecruiterByUserId", null);
    __decorate([
        Action(GetRecruiterById),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, GetRecruiterById]),
        __metadata("design:returntype", void 0)
    ], RecruitersState.prototype, "getRecruiterById", null);
    __decorate([
        Action(FindRecruitersByCompanyId),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], RecruitersState.prototype, "findRecruitersByCompanyId", null);
    __decorate([
        Action(AddAuthorizations),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, AddAuthorizations]),
        __metadata("design:returntype", void 0)
    ], RecruitersState.prototype, "addAuthorizations", null);
    __decorate([
        Action(DeleteAuthorizations),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, DeleteAuthorizations]),
        __metadata("design:returntype", void 0)
    ], RecruitersState.prototype, "deleteAuthorizations", null);
    __decorate([
        Action(GetRecruiterSuccess),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, GetRecruiterSuccess]),
        __metadata("design:returntype", void 0)
    ], RecruitersState.prototype, "getRecruiterSuccess", null);
    __decorate([
        Action(SetAuthenticatedRecruiter),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, SetAuthenticatedRecruiter]),
        __metadata("design:returntype", void 0)
    ], RecruitersState.prototype, "setAuthenticatedRecruiter", null);
    __decorate([
        Action(SetRecruiter),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, SetRecruiter]),
        __metadata("design:returntype", void 0)
    ], RecruitersState.prototype, "setApplication", null);
    __decorate([
        Action(HandleErrors),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, HandleErrors]),
        __metadata("design:returntype", void 0)
    ], RecruitersState.prototype, "handleErrors", null);
    __decorate([
        Selector(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [RecruitersStateModel]),
        __metadata("design:returntype", void 0)
    ], RecruitersState, "authenticatedRecruiter", null);
    __decorate([
        Selector(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [RecruitersStateModel]),
        __metadata("design:returntype", void 0)
    ], RecruitersState, "authenticatedRecruiterId", null);
    __decorate([
        Selector(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [RecruitersStateModel]),
        __metadata("design:returntype", void 0)
    ], RecruitersState, "list", null);
    __decorate([
        Selector(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [RecruitersStateModel]),
        __metadata("design:returntype", void 0)
    ], RecruitersState, "listLength", null);
    RecruitersState = RecruitersState_1 = __decorate([
        State({
            name: 'recruiters',
            defaults: defaultState,
        }),
        __metadata("design:paramtypes", [RecruiterWebservice,
            Store,
            NotificationsService])
    ], RecruitersState);
    return RecruitersState;
}());
export { RecruitersState };
