import { HttpErrorResponse } from '@angular/common/http';
import { UserOverview } from '@models/auth/user-overview.interface';
import { JwtTokens } from '@wizbii/angular-utilities';

export abstract class SessionFailed {
  static readonly type;
  constructor(public error: HttpErrorResponse | Error) {}
}

export class Init {
  static readonly type = '[Session] Init';
  constructor(public tokens?: JwtTokens) {}
}

export class InitUser {
  static readonly type = '[Session] Init User';
  constructor(public id: string) {}
}

export class UpdateUser {
  static readonly type = '[Session] Update User';
  constructor(public user: UserOverview) {}
}

export class SignIn {
  static readonly type = '[Session] Sign in';
  constructor(public username: string, public password: string) {}
}

export class SignInSuccess {
  static readonly type = '[Session] Sign in Success';
  constructor(public tokens: JwtTokens) {}
}

export class SignInFailed extends SessionFailed {
  static readonly type = '[Session] Sign in Failed';
}

export class SignUp {
  static readonly type = '[Session] Sign up';
  constructor(
    public firstname: string,
    public lastname: string,
    public company: string,
    public phone: string,
    public email: string,
    public password: string,
    public invitationToken?: string
  ) {}
}

export class SignUpWizbii {
  static readonly type = '[Session] Sign up Wizbii';
  constructor(
    public email: string,
    public firstname: string,
    public lastname: string,
    public company: string,
    public phone: string,
    public invitationToken?: string
  ) {}
}

export class SignUpFailed extends SessionFailed {
  static readonly type = '[Session] Sign up Failed';
}

export class RecoverPassword {
  static readonly type = '[Session] Recover Password';
  constructor(public email: string) {}
}

export class RecoverPasswordSuccess {
  static readonly type = '[Session] Recover Password Success';
}

export class RecoverPasswordFailed extends SessionFailed {
  static readonly type = '[Session] Recover Password Failed';
}

export class RefreshTokensStore {
  static readonly type = '[Session] Refresh Tokens Store';
  constructor(public tokens: JwtTokens) {}
}

export class Logout {
  static readonly type = '[Session] Logout';
}

export class SetTokens {
  static readonly type = '[Session] Set Tokens';
  constructor(public tokens: JwtTokens) {}
}

export class RemoveTokens {
  static readonly type = '[Session] Remove Tokens';
}
