var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import { Action, createSelector, State } from '@ngxs/store';
import { SetConsent, SetConsents } from '@store/consents/consents.actions';
import { fromEvent } from 'rxjs';
var ConsentsState = /** @class */ (function () {
    function ConsentsState(document) {
        this.document = document;
    }
    ConsentsState_1 = ConsentsState;
    ConsentsState.consent = function (key) {
        return createSelector([ConsentsState_1], function (state) { return state[key]; });
    };
    ConsentsState.prototype.ngxsAfterBootstrap = function (ctx) {
        fromEvent(this.document, 'WizbiiGdpr.consentsChange').subscribe(function (_a) {
            var consent = _a.WizbiiGdpr.consent;
            ctx.dispatch(new SetConsents(consent));
        });
        // Listen for a consent changed on its own
        fromEvent(this.document, 'WizbiiGdpr.consentChange').subscribe(function (_a) {
            var consent = _a.WizbiiGdpr.consent;
            ctx.dispatch(new SetConsent(consent));
        });
    };
    ConsentsState.prototype.setConsents = function (ctx, _a) {
        var consents = _a.consents;
        ctx.setState([].concat.apply([], __spread(Object.values(consents))).reduce(function (acc, current) {
            acc[current.key] = current.value;
            return acc;
        }, {}));
    };
    ConsentsState.prototype.setConsent = function (ctx, _a) {
        var _b;
        var consent = _a.consent;
        ctx.patchState((_b = {}, _b[consent.key] = consent.value, _b));
    };
    var ConsentsState_1;
    __decorate([
        Action(SetConsents),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, SetConsents]),
        __metadata("design:returntype", void 0)
    ], ConsentsState.prototype, "setConsents", null);
    __decorate([
        Action(SetConsent),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, SetConsent]),
        __metadata("design:returntype", void 0)
    ], ConsentsState.prototype, "setConsent", null);
    ConsentsState = ConsentsState_1 = __decorate([
        State({
            name: 'consents',
            defaults: {},
        }),
        __metadata("design:paramtypes", [Object])
    ], ConsentsState);
    return ConsentsState;
}());
export { ConsentsState };
