import { isPlatformBrowser } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Inject, Injectable, PLATFORM_ID } from '@angular/core';
import bugsnag from '@bugsnag/js';
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';
import { environment } from '@env/environment';

interface MessageGroupingRule {
  regex: RegExp;
  msg: string;
}

const REPORTED_ENVS = ['qa3', 'qa4', 'prod', 'staging', 'dev'];
const SILENCED_SUFFIX = ' (silenced)';

const MESSAGE_GROUPS: MessageGroupingRule[] = [
  { regex: /(Loading chunk) [0-9]+ (failed)[^]*/, msg: '$1 $2' },
  { regex: /(Uncaught \(in promise\): e: {)[^]*(\"status\":[0-9]{1,3},\"statusText\":\"[^"]+\")[^]*$/, msg: '$1$2}' },
  { regex: /(\/profiles)\/[^/]+(.*):/, msg: '$1/<profile-id>$2:' },
  { regex: /(\/user)\/[^/]+(.*):/, msg: '$1/<user-id>$2:' },
  { regex: /(\/company)\/[^/]+(.*):/, msg: '$1/<company-id>$2:' },
  { regex: /(\/jobs)\/[^/]+(.*):/, msg: '$1/<job-id>$2:' },
  { regex: /(\/schools)\/[^/]+(.*):/, msg: '$1/<school-id>$2:' },
  { regex: /(\/location\?name=)[^:]+/, msg: '$1<keyword>' },
  { regex: /(\/location)\/[^:]+/, msg: '$1/<location-id>' },
  { regex: /(\/wizbii-files)\/[^:]+/, msg: '$1/<file-name>' },
  { regex: /(\/m-api.wizbii.com\/v1)\/[^:]+/, msg: '$1/<media-endpoint>' },
  { regex: /(animation trigger ".+" has failed to build)[^]*/, msg: '$1' },
  { regex: /(JSONP request)[^]*(failed|timed out)$/, msg: '$1 $2' },
];

@Injectable()
export class ErrorHandlerService extends BugsnagErrorHandler {
  /**
   * Silence incidental NGXS errors.
   * Workaround for handling errors outside of NGXS states as per ADR-001.
   * https://github.com/ngxs/store/issues/781
   */
  static silence(error: Error, condition: boolean): void {
    if (condition) {
      error.message = `${error.message}${SILENCED_SUFFIX}`;
    }
  }

  constructor(@Inject(PLATFORM_ID) platformId: Object) {
    super(
      isPlatformBrowser(platformId)
        ? bugsnag({
            apiKey: environment.bugsnagId,
            appVersion: environment.version,
            autoCaptureSessions: false,
            releaseStage: environment.name,
            beforeSend: report => {
              if (!REPORTED_ENVS.includes(environment.name)) {
                report.ignore();
                return;
              }

              // Pass error message through all known message grouping rules
              const groupedErrorMessage = MESSAGE_GROUPS.reduce(
                (message, { regex, msg }) => message.replace(regex, msg),
                report.errorMessage
              );

              // Group errors by class and message
              report.groupingHash = `${report.errorClass}: ${groupedErrorMessage}`;
            },
          })
        : // Can't initialise Bugsnag in SSR
          // https://github.com/bugsnag/bugsnag-js/issues/455
          null
    );
  }

  /**
   * Override Angular's and Bugsnag's default error handling
   * in order to silence some errors and deal with SSR.
   */
  handleError(error: Error): void {
    if (error.message && error.message.endsWith(SILENCED_SUFFIX)) {
      // Log silenced error
      console.warn(error.message);
    } else if (this.bugsnagClient) {
      // In browser, handle error with Bugsnag
      super.handleError(error);
    } else {
      // In SSR, skip Bugsnag and use Angular's error handler directly
      // https://github.com/bugsnag/bugsnag-js/issues/455
      ErrorHandler.prototype.handleError.call(this, error);
    }
  }

  /**
   * Notify Bugsnag of a known, handled error.
   */
  warn(message: string, error: HttpErrorResponse | Error | object = {}): void {
    console.warn(message);

    if (this.bugsnagClient) {
      this.bugsnagClient.notify(message, {
        severity: 'info',
        metaData: {
          debug: error instanceof Error ? { type: error.name, message: error.message } : error,
        },
      });
    }
  }
}
