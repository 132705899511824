import { ErrorHandler, Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { RoleStatusEnum } from '@ats/models';
import { ErrorHandlerService } from '@core/services/error-handler.service';
import { FeaturesRoutingEnum } from '@features/features-routing.enum';
import { Store } from '@ngxs/store';
import { RecruitersState } from '@store/recruiters/recruiters.state';
import { SessionState } from '@store/session/session.state';
import { Observable, of } from 'rxjs';
import { filter, first, map, switchMap } from 'rxjs/operators';

@Injectable()
export class HasAuthorizationsGuard implements CanActivate {
  private readonly failureRedirection: Record<RoleStatusEnum, string> = {
    [RoleStatusEnum.recruitment]: `/${FeaturesRoutingEnum.Jobs}`,
    [RoleStatusEnum.career]: `/${FeaturesRoutingEnum.Company}`,
    [RoleStatusEnum.admin]: `/${FeaturesRoutingEnum.Contributors}`,
  };

  constructor(
    private readonly router: Router,
    private readonly store: Store,
    @Inject(ErrorHandler) private readonly errorHandlerService: ErrorHandlerService
  ) {}

  // tslint:disable-next-line:cognitive-complexity
  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    const authorizations: RoleStatusEnum[] = route.data.authorizations;

    if (!authorizations || authorizations.length === 0) {
      return of(true);
    }

    return this.store.select(SessionState.isInitialized).pipe(
      filter(isInitialized => isInitialized),
      first(),
      switchMap(() => this.store.selectOnce(RecruitersState.authenticatedRecruiter)),
      map(recruiter => {
        const hasAccess = authorizations.every(authorization =>
          this.hasAuthorization(recruiter.authorizations, authorization)
        );

        if (!hasAccess) {
          this.errorHandlerService.warn('Unauthorized route, not enough authorizations.');
        }

        if (!hasAccess && this.hasAuthorization(recruiter.authorizations, RoleStatusEnum.recruitment)) {
          return this.router.parseUrl(this.failureRedirection[RoleStatusEnum.recruitment]);
        }

        if (!hasAccess && this.hasAuthorization(recruiter.authorizations, RoleStatusEnum.career)) {
          return this.router.parseUrl(this.failureRedirection[RoleStatusEnum.career]);
        }

        if (!hasAccess && this.hasAuthorization(recruiter.authorizations, RoleStatusEnum.admin)) {
          return this.router.parseUrl(this.failureRedirection[RoleStatusEnum.admin]);
        }

        if (!hasAccess && (recruiter.authorizations || []).length === 0) {
          return this.router.parseUrl(`/${FeaturesRoutingEnum.Profile}`);
        }

        return hasAccess;
      })
    );
  }

  private hasAuthorization(authorizations: RoleStatusEnum[], authorization: RoleStatusEnum): boolean {
    return (authorizations || []).includes(authorization);
  }
}
