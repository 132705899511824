import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Candidate, toCandidate } from '@ats/models';
import { environment } from '@env/environment';
import { buildUrl } from '@wizbii/angular-utilities';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface CountStatusInterface {
  count: number;
  jobId: string;
}

@Injectable()
export class CandidateWebservice {
  private readonly countUrl = `${environment.api.candidate}/v1/candidates/count-by-job-ids-and-statuses`;

  constructor(private readonly http: HttpClient) {}

  get(id: string): Observable<Candidate> {
    const url = buildUrl(`${environment.api.candidate}/v1/candidates/:id`, { id });

    return this.http.get<object>(url).pipe(map(toCandidate));
  }

  getCountCandidatesStatus(companyId: string, statuses?: string[]): Observable<CountStatusInterface[]> {
    const url = buildUrl(this.countUrl, { companyId, statuses: statuses ? statuses.join() : undefined });

    return this.http.get<{ results: CountStatusInterface[] }>(url).pipe(map(results => results.results));
  }

  findCandidates(jobId: string): Observable<Candidate[]> {
    return this.http.get<{ results: object[] }>(buildUrl(`${environment.api.candidate}/v1/candidates`, { jobId })).pipe(
      map(response => response.results),
      map(applications => applications.map(toCandidate))
    );
  }

  patch(form: FormGroup, candidate: Candidate): Observable<Candidate> {
    const url = buildUrl(`${environment.api.candidate}/v1/candidates/:id`, { id: candidate.id });
    const candidateUpdated = this.formToResource(form, candidate);

    return this.http
      .patch(url, candidateUpdated, {
        headers: { ignoreLoadingBar: '' },
      })
      .pipe(
        map(() => candidateUpdated),
        map(toCandidate)
      );
  }

  private formToResource(form: FormGroup, candidate?: Candidate): Candidate {
    return !candidate
      ? toCandidate({
          firstName: form.value.firstname,
          lastName: form.value.name,
          email: form.value.email,
          phone: form.value.phone === '' ? null : form.value.phone,
          location: form.value.city === '' ? null : form.value.city,
          currentJob: form.value.job === '' ? null : form.value.job,
        })
      : toCandidate({
          ...candidate,
          firstName: form.value.firstname,
          lastName: form.value.name,
          email: form.value.email,
          phone: form.value.phone === '' ? null : form.value.phone,
          location: form.value.city === '' ? null : form.value.city,
          currentJob: form.value.job === '' ? null : form.value.job,
        });
  }
}
