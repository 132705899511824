import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RoleStatusEnum } from '@ats/models';
import { HasAuthorizationsGuard } from '@core/guards/has-authorizations/has-authorizations.guard';
import { InitGuard } from '@core/guards/init/init.guard';
import { IsAlreadyConnectedGuard } from '@core/guards/is-already-connected/is-already-connected.guard';
import { MustBeConnectedGuard } from '@core/guards/must-be-connected/must-be-connected.guard';
import { MustHaveRecruiterGuard } from '@core/guards/must-have-recruiter/must-have-recruiter.guard';
import { FeaturesRoutingEnum } from '@features/features-routing.enum';
import { NotFoundComponent } from '@features/not-found/not-found.component';

const routes: Routes = [
  {
    path: FeaturesRoutingEnum.Root,
    pathMatch: 'full',
    redirectTo: `/${FeaturesRoutingEnum.SignIn}`,
  },
  {
    path: FeaturesRoutingEnum.Wizbii,
    canActivate: [InitGuard],
    loadChildren: () => import('./wizbii/wizbii.module').then(m => m.WizbiiModule),
  },
  {
    path: FeaturesRoutingEnum.SignIn,
    canActivate: [InitGuard, IsAlreadyConnectedGuard],
    loadChildren: () => import('./sign-in/sign-in.module').then(m => m.SignInModule),
  },
  {
    path: FeaturesRoutingEnum.SignUp,
    canActivate: [InitGuard, IsAlreadyConnectedGuard],
    loadChildren: () => import('./sign-up/sign-up.module').then(m => m.SignUpModule),
  },
  {
    path: FeaturesRoutingEnum.LostPassword,
    canActivate: [InitGuard],
    loadChildren: () => import('./lost-password/lost-password.module').then(m => m.LostPasswordModule),
  },
  {
    path: FeaturesRoutingEnum.Jobs,
    canActivate: [InitGuard, MustBeConnectedGuard, MustHaveRecruiterGuard, HasAuthorizationsGuard],
    data: { authorizations: [RoleStatusEnum.recruitment] },
    loadChildren: () => import('./jobs/jobs.module').then(m => m.JobsModule),
  },
  {
    path: FeaturesRoutingEnum.Company,
    canActivate: [InitGuard, MustBeConnectedGuard, MustHaveRecruiterGuard, HasAuthorizationsGuard],
    data: { authorizations: [RoleStatusEnum.career] },
    loadChildren: () => import('./company/company.module').then(m => m.CompanyModule),
  },
  {
    path: FeaturesRoutingEnum.Profile,
    canActivate: [InitGuard, MustBeConnectedGuard, MustHaveRecruiterGuard],
    loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule),
  },
  {
    path: FeaturesRoutingEnum.Parameters,
    canActivate: [InitGuard, MustBeConnectedGuard, MustHaveRecruiterGuard],
    loadChildren: () => import('./parameters/parameters.module').then(m => m.ParametersModule),
  },
  {
    path: FeaturesRoutingEnum.Confidentiality,
    canActivate: [InitGuard, MustBeConnectedGuard, MustHaveRecruiterGuard],
    loadChildren: () => import('./confidentiality/confidentiality.module').then(m => m.ConfidentialityModule),
  },
  {
    path: FeaturesRoutingEnum.Contributors,
    canActivate: [InitGuard, MustBeConnectedGuard, HasAuthorizationsGuard],
    data: { authorizations: [RoleStatusEnum.admin] },
    loadChildren: () => import('./contributors/contributors.module').then(m => m.ContributorsModule),
  },
  {
    path: FeaturesRoutingEnum.NotAuthorized,
    loadChildren: () => import('./not-authorized/not-authorized.module').then(m => m.NotAuthorizedModule),
  },
  {
    path: FeaturesRoutingEnum.NotFound,
    component: NotFoundComponent,
    canActivate: [InitGuard],
  },
  {
    path: '**',
    component: NotFoundComponent,
    canActivate: [InitGuard],
    data: { unhandled404: true },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class FeaturesRoutingModule {}
