import { ChangeDetectionStrategy, Component, Inject, OnInit, Optional } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Company, Recruiter } from '@ats/models';
import { RouterExtensionService } from '@core/services/router-extension.service';
import { RESPONSE } from '@nguniversal/express-engine/tokens';
import { Select } from '@ngxs/store';
import { CompaniesState } from '@store/companies/companies.state';
import { RecruitersState } from '@store/recruiters/recruiters.state';
import { Observable } from 'rxjs';

@Component({
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotFoundComponent implements OnInit {
  @Select(RecruitersState.authenticatedRecruiter)
  recruiter$: Observable<Recruiter>;

  @Select(CompaniesState.authenticatedCompany)
  company$: Observable<Company>;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly routerExtension: RouterExtensionService,
    @Optional() @Inject(RESPONSE) private readonly response: any
  ) {}

  ngOnInit() {
    if (this.response) {
      this.response.statusCode = 404;
      this.response.statusMessage = 'Not Found';
    } else if (this.route.snapshot.data.unhandled404 && this.routerExtension.previousUrl) {
      throw new Error('Unhandled 404');
    }
  }
}
