/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./notifications.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../svg-icon/svg-icon.component.ngfactory";
import * as i3 from "../svg-icon/svg-icon.component";
import * as i4 from "@angular/common";
import * as i5 from "./notifications.component";
import * as i6 from "./notifications.service";
import * as i7 from "@angular/platform-browser";
var styles_NotificationsComponent = [i0.styles];
var RenderType_NotificationsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NotificationsComponent, data: { "animation": [{ type: 7, name: "animateToasts", definitions: [{ type: 0, name: "void", styles: { type: 6, styles: { opacity: 0 }, offset: null }, options: undefined }, { type: 1, expr: ":enter, :leave", animation: [{ type: 4, styles: null, timings: "0.3s ease" }], options: null }], options: {} }] } });
export { RenderType_NotificationsComponent as RenderType_NotificationsComponent };
function View_NotificationsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "svg-icon", [["icon", "exp-valid"], ["width", "1.5em"]], null, null, null, i2.View_SvgIconComponent_0, i2.RenderType_SvgIconComponent)), i1.ɵdid(1, 49152, null, 0, i3.SvgIconComponent, [], { icon: [0, "icon"], width: [1, "width"] }, null)], function (_ck, _v) { var currVal_0 = "exp-valid"; var currVal_1 = "1.5em"; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_NotificationsComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "svg-icon", [["icon", "exp-alert"], ["width", "1.5em"]], null, null, null, i2.View_SvgIconComponent_0, i2.RenderType_SvgIconComponent)), i1.ɵdid(1, 49152, null, 0, i3.SvgIconComponent, [], { icon: [0, "icon"], width: [1, "width"] }, null)], function (_ck, _v) { var currVal_0 = "exp-alert"; var currVal_1 = "1.5em"; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_NotificationsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "notification-message__picto"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationsComponent_4)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationsComponent_5)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.parent.context.$implicit.style === _co.NotifStatus.Success); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_v.parent.context.$implicit.style === _co.NotifStatus.Failed); _ck(_v, 4, 0, currVal_1); }, null); }
function View_NotificationsComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "btn-clean notification__button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (_v.parent.context.$implicit.callbackConfig.callback() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.callbackConfig.text; _ck(_v, 1, 0, currVal_0); }); }
function View_NotificationsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "notification"]], [[24, "@animateToasts", 0]], null, null, null, null)), i1.ɵprd(512, null, i4.ɵNgClassImpl, i4.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i4.NgClass, [i4.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 0, "div", [["class", "notification__bar"]], [[4, "animation-duration", null]], null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "svg-icon", [["class", "notification__close"], ["height", ".6em"], ["icon", "exp-cross"], ["width", ".6em"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.removeNotification(_v.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_SvgIconComponent_0, i2.RenderType_SvgIconComponent)), i1.ɵdid(5, 49152, null, 0, i3.SvgIconComponent, [], { icon: [0, "icon"], width: [1, "width"], height: [2, "height"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [["class", "notification-message"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationsComponent_3)), i1.ɵdid(8, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 1, "div", [["class", "notification-message__content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 0, "p", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationsComponent_6)), i1.ɵdid(12, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_1 = "notification"; var currVal_2 = ("notification--" + _v.context.$implicit.style); _ck(_v, 2, 0, currVal_1, currVal_2); var currVal_4 = "exp-cross"; var currVal_5 = ".6em"; var currVal_6 = ".6em"; _ck(_v, 5, 0, currVal_4, currVal_5, currVal_6); var currVal_7 = _v.context.$implicit.style; _ck(_v, 8, 0, currVal_7); var currVal_9 = _v.context.$implicit.callbackConfig; _ck(_v, 12, 0, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = undefined; _ck(_v, 0, 0, currVal_0); var currVal_3 = ((_v.context.$implicit.duration || _co.duration) + "ms"); _ck(_v, 3, 0, currVal_3); var currVal_8 = _v.context.$implicit.message; _ck(_v, 10, 0, currVal_8); }); }
function View_NotificationsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "notifications"]], [[24, "@animateToasts", 0]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationsComponent_2)), i1.ɵdid(2, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.notifications; var currVal_2 = _co.trackById; _ck(_v, 2, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = undefined; _ck(_v, 0, 0, currVal_0); }); }
export function View_NotificationsComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationsComponent_1)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_co.notifications == null) ? null : _co.notifications.length) !== 0); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_NotificationsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-notifications", [], null, null, null, View_NotificationsComponent_0, RenderType_NotificationsComponent)), i1.ɵdid(1, 245760, null, 0, i5.NotificationsComponent, [i6.NotificationsService, i1.ChangeDetectorRef, i7.DomSanitizer], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NotificationsComponentNgFactory = i1.ɵccf("app-notifications", i5.NotificationsComponent, View_NotificationsComponent_Host_0, {}, {}, []);
export { NotificationsComponentNgFactory as NotificationsComponentNgFactory };
