<svg
  *ngIf="width"
  class="svg-icon"
  [ngClass]="classes"
  [attr.width]="width"
  [attr.height]="height || width"
  [attr.viewBox]="viewBox"
  [attr.preserveAspectRatio]="preserveAspectRatio"
  [attr.role]="label ? 'img' : 'presentation'"
  [attr.aria-label]="label"
  focusable="false"
>
  <use [attr.xlink:href]="'#icon-' + icon"></use>
</svg>
