import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Store } from '@ngxs/store';
import { Init } from '@store/session/session.actions';
import { SessionState } from '@store/session/session.state';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Injectable()
export class InitGuard implements CanActivate {
  constructor(private readonly store: Store) {}

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return this.store.selectOnce(SessionState.isInitialized).pipe(
      switchMap(isInitialized => (!isInitialized ? this.store.dispatch(new Init()) : of(undefined))),
      map(() => true)
    );
  }
}
