import { Router } from '@angular/router';
import { RoleStatusEnum } from '@ats/models';
import { ErrorHandlerService } from '@core/services/error-handler.service';
import { FeaturesRoutingEnum } from '@features/features-routing.enum';
import { Store } from '@ngxs/store';
import { RecruitersState } from '@store/recruiters/recruiters.state';
import { SessionState } from '@store/session/session.state';
import { of } from 'rxjs';
import { filter, first, map, switchMap } from 'rxjs/operators';
var HasAuthorizationsGuard = /** @class */ (function () {
    function HasAuthorizationsGuard(router, store, errorHandlerService) {
        var _a;
        this.router = router;
        this.store = store;
        this.errorHandlerService = errorHandlerService;
        this.failureRedirection = (_a = {},
            _a[RoleStatusEnum.recruitment] = "/" + FeaturesRoutingEnum.Jobs,
            _a[RoleStatusEnum.career] = "/" + FeaturesRoutingEnum.Company,
            _a[RoleStatusEnum.admin] = "/" + FeaturesRoutingEnum.Contributors,
            _a);
    }
    // tslint:disable-next-line:cognitive-complexity
    HasAuthorizationsGuard.prototype.canActivate = function (route) {
        var _this = this;
        var authorizations = route.data.authorizations;
        if (!authorizations || authorizations.length === 0) {
            return of(true);
        }
        return this.store.select(SessionState.isInitialized).pipe(filter(function (isInitialized) { return isInitialized; }), first(), switchMap(function () { return _this.store.selectOnce(RecruitersState.authenticatedRecruiter); }), map(function (recruiter) {
            var hasAccess = authorizations.every(function (authorization) {
                return _this.hasAuthorization(recruiter.authorizations, authorization);
            });
            if (!hasAccess) {
                _this.errorHandlerService.warn('Unauthorized route, not enough authorizations.');
            }
            if (!hasAccess && _this.hasAuthorization(recruiter.authorizations, RoleStatusEnum.recruitment)) {
                return _this.router.parseUrl(_this.failureRedirection[RoleStatusEnum.recruitment]);
            }
            if (!hasAccess && _this.hasAuthorization(recruiter.authorizations, RoleStatusEnum.career)) {
                return _this.router.parseUrl(_this.failureRedirection[RoleStatusEnum.career]);
            }
            if (!hasAccess && _this.hasAuthorization(recruiter.authorizations, RoleStatusEnum.admin)) {
                return _this.router.parseUrl(_this.failureRedirection[RoleStatusEnum.admin]);
            }
            if (!hasAccess && (recruiter.authorizations || []).length === 0) {
                return _this.router.parseUrl("/" + FeaturesRoutingEnum.Profile);
            }
            return hasAccess;
        }));
    };
    HasAuthorizationsGuard.prototype.hasAuthorization = function (authorizations, authorization) {
        return (authorizations || []).includes(authorization);
    };
    return HasAuthorizationsGuard;
}());
export { HasAuthorizationsGuard };
