import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { NotificationsComponent } from '@commons/notifications/notifications.component';
import { NotificationsService } from '@commons/notifications/notifications.service';
import { SvgIconModule } from '@commons/svg-icon/svg-icon.module';

@NgModule({
  declarations: [NotificationsComponent],
  imports: [CommonModule, SvgIconModule],
  exports: [NotificationsComponent],
  providers: [NotificationsService],
})
export class NotificationsModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: NotificationsModule,
      providers: [NotificationsService],
    };
  }
}
