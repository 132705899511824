<app-popup (openedChange)="show = !show">
  <button
    appEventTracker
    eventCategory="App Navigation"
    eventAction="Clicks Menu"
    class="recruiter btn-clean"
    type="button"
    appPopupTrigger
  >
    <span class="recruiter__name"> {{ recruiter?.firstName }}&nbsp;{{ recruiter?.lastName }} </span>

    <app-logo
      class="recruiter__logo"
      [item]="{ logoUrl: company?.logo, firstName: recruiter?.firstName, lastName: recruiter?.lastName }"
      background="#8782ff"
      size="small"
    ></app-logo>

    <svg-icon
      class="recruiter__chevron-down"
      [class.recruiter__chevron-down--rotate]="show"
      icon="exp-chevron-down"
      width="0.65em"
      height="0.5em"
    ></svg-icon>
  </button>

  <div class="profile-popup-menu" appPopupItem>
    <a
      appEventTracker
      eventCategory="App Navigation"
      eventAction="Click Profile"
      class="profile-popup-menu__link"
      [routerLink]="['/', featuresRoutingEnum.Profile]"
    >
      <svg-icon class="profile-popup-menu__link__icon" icon="exp-account" width="1em"></svg-icon>
      <ng-container i18n>Mon profil</ng-container>
    </a>

    <a
      appEventTracker
      eventCategory="App Navigation"
      eventAction="Click Settings"
      class="profile-popup-menu__link"
      [routerLink]="['/', featuresRoutingEnum.Parameters]"
    >
      <svg-icon class="profile-popup-menu__link__icon" icon="exp-search-settings" width="1em"></svg-icon>
      <ng-container i18n>Paramètres</ng-container>
    </a>

    <a
      appEventTracker
      eventCategory="App Navigation"
      eventAction="Click Privacy Policy"
      class="profile-popup-menu__link"
      [routerLink]="['/', featuresRoutingEnum.Confidentiality]"
    >
      <svg-icon class="profile-popup-menu__link__icon" icon="exp-lock" width="1em"></svg-icon>
      <ng-container i18n>Confidentialité</ng-container>
    </a>

    <button
      appEventTracker
      eventCategory="App Navigation"
      eventAction="Click Logout"
      type="button"
      class="profile-popup-menu__link btn-clean"
      (click)="doLogout($event)"
    >
      <svg-icon class="profile-popup-menu__link__icon" icon="exp-lock-is-open" width="1em"></svg-icon>
      <ng-container i18n>Se déconnecter</ng-container>
    </button>
  </div>
</app-popup>
