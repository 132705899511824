var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { CompanyWebservice } from '@ats/webservices';
import { atsDomain } from '@env/environment';
import { FeaturesRoutingEnum } from '@features/features-routing.enum';
import { Navigate } from '@ngxs/router-plugin';
import { Action, createSelector, Selector, State } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { GetCompany, GetCompanySuccess, HandleErrors, SetAuthenticatedCompany, UpdateCompany, } from '@store/companies/companies.actions';
import { catchError, switchMap } from 'rxjs/operators';
var CompaniesStateModel = /** @class */ (function () {
    function CompaniesStateModel() {
    }
    return CompaniesStateModel;
}());
export { CompaniesStateModel };
var defaultState = {
    authenticatedCompanyId: null,
    companies: {},
    error: null,
};
export var companyRefusedText = 'Company refused';
var CompaniesState = /** @class */ (function () {
    function CompaniesState(companyWebservice) {
        this.companyWebservice = companyWebservice;
    }
    CompaniesState_1 = CompaniesState;
    CompaniesState.company = function (companyId) {
        return createSelector([CompaniesState_1], function (state) {
            return state.companies[companyId];
        });
    };
    CompaniesState.authenticatedCompany = function (state) {
        return state.companies[state.authenticatedCompanyId];
    };
    CompaniesState.authenticatedCompanyId = function (state) {
        return state.authenticatedCompanyId;
    };
    CompaniesState.authenticatedCompanyCareerSite = function (state) {
        return "https://" + state.authenticatedCompanyId + "." + atsDomain;
    };
    CompaniesState.prototype.getCompany = function (ctx, _a) {
        var id = _a.id;
        var companies = ctx.getState().companies;
        if (!companies[id]) {
            return this.companyWebservice.get(id).pipe(switchMap(function (company) { return ctx.dispatch(new GetCompanySuccess(company)); }), catchError(function (error) { return ctx.dispatch(new HandleErrors(error)); }));
        }
    };
    CompaniesState.prototype.getCompanySuccess = function (ctx, _a) {
        var _b;
        var company = _a.company;
        return ctx.setState(patch({
            companies: patch((_b = {},
                _b[company._id] = company,
                _b)),
        }));
    };
    CompaniesState.prototype.setAuthenticatedCompany = function (ctx, _a) {
        var id = _a.id;
        ctx.patchState({ authenticatedCompanyId: id });
        return ctx.dispatch(new GetCompany(id));
    };
    CompaniesState.prototype.updateCompany = function (ctx, _a) {
        var _b;
        var company = _a.company;
        return ctx.setState(patch({
            companies: patch((_b = {}, _b[company._id] = company, _b)),
        }));
    };
    CompaniesState.prototype.handleErrors = function (ctx, _a) {
        var error = _a.error;
        // tslint:disable no-small-switch
        switch (error.status) {
            // The Company doesn't exist so => 404
            case 404: {
                if (error.error === companyRefusedText) {
                    console.error(companyRefusedText);
                    ctx.patchState({ error: error });
                    return ctx.dispatch(new Navigate(['/', FeaturesRoutingEnum.NotAuthorized], undefined, { skipLocationChange: true }));
                }
                console.error('Not found');
                ctx.patchState({ error: error });
                return ctx.dispatch(new Navigate(['/', FeaturesRoutingEnum.NotFound], undefined, { skipLocationChange: true }));
            }
            default: {
                console.error("Code " + error.status + " => " + error.statusText);
                return ctx.patchState({ error: error });
            }
        }
        // tslint:enable no-small-switch
    };
    var CompaniesState_1;
    __decorate([
        Action(GetCompany),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, GetCompany]),
        __metadata("design:returntype", void 0)
    ], CompaniesState.prototype, "getCompany", null);
    __decorate([
        Action(GetCompanySuccess),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, GetCompanySuccess]),
        __metadata("design:returntype", void 0)
    ], CompaniesState.prototype, "getCompanySuccess", null);
    __decorate([
        Action(SetAuthenticatedCompany),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, SetAuthenticatedCompany]),
        __metadata("design:returntype", void 0)
    ], CompaniesState.prototype, "setAuthenticatedCompany", null);
    __decorate([
        Action(UpdateCompany),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, UpdateCompany]),
        __metadata("design:returntype", void 0)
    ], CompaniesState.prototype, "updateCompany", null);
    __decorate([
        Action(HandleErrors),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, HandleErrors]),
        __metadata("design:returntype", void 0)
    ], CompaniesState.prototype, "handleErrors", null);
    __decorate([
        Selector(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [CompaniesStateModel]),
        __metadata("design:returntype", void 0)
    ], CompaniesState, "authenticatedCompany", null);
    __decorate([
        Selector(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [CompaniesStateModel]),
        __metadata("design:returntype", void 0)
    ], CompaniesState, "authenticatedCompanyId", null);
    __decorate([
        Selector(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [CompaniesStateModel]),
        __metadata("design:returntype", void 0)
    ], CompaniesState, "authenticatedCompanyCareerSite", null);
    CompaniesState = CompaniesState_1 = __decorate([
        State({
            name: 'companies',
            defaults: defaultState,
        }),
        __metadata("design:paramtypes", [CompanyWebservice])
    ], CompaniesState);
    return CompaniesState;
}());
export { CompaniesState };
