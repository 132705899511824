import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { EventTrackerDirective } from '@commons/tracker/event-tracker/event-tracker.directive';
import { EventTrackerService } from '@commons/tracker/event-tracker/event-tracker.service';
import { NgxsModule } from '@ngxs/store';
import { ConsentsState } from '@store/consents/consents.state';

@NgModule({
  imports: [CommonModule, HttpClientModule, NgxsModule.forFeature([ConsentsState])],
  declarations: [EventTrackerDirective],
  exports: [EventTrackerDirective],
})
export class TrackerModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: TrackerModule,
      providers: [EventTrackerService],
    };
  }
}
