var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { LocaleEnum } from '@models/commons/locales.enum';
var AccountApiWebservice = /** @class */ (function () {
    function AccountApiWebservice(http, localeId) {
        this.http = http;
        this.localeId = localeId;
        this.baseUrl = environment.api.account + "/v1/account";
    }
    AccountApiWebservice.prototype.create = function (recruiter, company, account, invitationToken) {
        var payload = __assign({ recruiter: recruiter,
            company: company }, (account
            ? {
                account: __assign({}, account, { appId: environment.applicationId, locale: LocaleEnum[this.localeId] }),
            }
            : {}), (invitationToken ? { invitationToken: invitationToken } : null));
        return this.http.post(this.baseUrl + "/recruiters", payload, {
            withCredentials: true,
            headers: { ignoreLoadingBar: '' },
        });
    };
    return AccountApiWebservice;
}());
export { AccountApiWebservice };
