import { HttpErrorResponse } from '@angular/common/http';
import { Application, Candidate } from '@ats/models';

export class InitStaticData {
  static readonly type = '[Application] Init static data';
}

export class SetApplication {
  static readonly type = '[Application] Set';

  constructor(
    public application: Application,
    public previousApplication?: Application,
    public candidate?: Candidate
  ) {}
}

export class FindApplications {
  static readonly type = '[Application] Find';
  constructor(public jobId: string) {}
}

export class HandleErrors {
  static readonly type = '[Application] Handle Errors';
  constructor(public error: HttpErrorResponse) {}
}
