var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { HttpErrorResponse } from '@angular/common/http';
import { ApplicationWebservice } from '@ats/webservices';
import { NotifStatus } from '@commons/notifications/notif-status.enum';
import { NotificationsService } from '@commons/notifications/notifications.service';
import { FeaturesRoutingEnum } from '@features/features-routing.enum';
import { Navigate } from '@ngxs/router-plugin';
import { Action, createSelector, Selector, State } from '@ngxs/store';
import { iif, insertItem, patch, updateItem } from '@ngxs/store/operators';
import { FindApplications, HandleErrors, InitStaticData, SetApplication, } from '@store/applications/applications.actions';
import { buildUrl } from '@wizbii/angular-utilities';
import { of, throwError, zip } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
var ApplicationStateModel = /** @class */ (function () {
    function ApplicationStateModel() {
    }
    return ApplicationStateModel;
}());
export { ApplicationStateModel };
var defaultState = {
    applications: {},
    status: [],
    sourcesPlatforms: [],
    error: null,
};
var ApplicationsState = /** @class */ (function () {
    function ApplicationsState(applicationWebservice, notifService) {
        this.applicationWebservice = applicationWebservice;
        this.notifService = notifService;
    }
    ApplicationsState_1 = ApplicationsState;
    ApplicationsState.status = function (state) {
        return state.status;
    };
    ApplicationsState.sourcesPlatforms = function (state) {
        return state.sourcesPlatforms;
    };
    ApplicationsState.sourcePlatform = function (name) {
        return createSelector([ApplicationsState_1], function (state) {
            return state.sourcesPlatforms.find(function (source) { return name.toLowerCase().includes(source.utm.source.toLowerCase()); });
        });
    };
    ApplicationsState.application = function (jobId, applicationId) {
        return createSelector([ApplicationsState_1], function (state) { return state.applications[jobId].find(function (application) { return application.id === applicationId; }); });
    };
    ApplicationsState.prototype.initStaticData = function (ctx) {
        var _a = ctx.getState(), status = _a.status, sourcesPlatforms = _a.sourcesPlatforms;
        if (status.length > 0 && sourcesPlatforms.length > 0) {
            return undefined;
        }
        return zip(this.applicationWebservice.getStatus({
            headers: { ignoreLoadingBar: '' },
        }), this.applicationWebservice.getSourcesPlatform({
            headers: { ignoreLoadingBar: '' },
        })).pipe(switchMap(function (_a) {
            var _b = __read(_a, 2), _status = _b[0], _sourcesPlatforms = _b[1];
            return of(ctx.patchState({
                status: _status,
                sourcesPlatforms: _sourcesPlatforms,
            }));
        }), catchError(function (err) {
            if (err instanceof HttpErrorResponse && err.status === 500) {
                return throwError(new Error('Internal Server Error while getting datas'));
            }
            return throwError(err);
        }));
    };
    ApplicationsState.prototype.setApplication = function (ctx, _a) {
        var _b;
        var application = _a.application;
        return ctx.setState(patch({
            applications: patch((_b = {},
                _b[application.jobId] = iif(function (applications) { return !!applications.find(function (app) { return app.id === application.id; }); }, updateItem(function (app) { return app.id === application.id; }, application), insertItem(application)),
                _b)),
        }));
    };
    ApplicationsState.prototype.getApplications = function (ctx, _a) {
        var jobId = _a.jobId;
        return this.applicationWebservice.findApplications(jobId).pipe(switchMap(function (applications) {
            var _a;
            ctx.setState(patch({
                applications: (_a = {},
                    _a[jobId] = applications,
                    _a),
            }));
            return of(applications);
        }), catchError(function (error) { return ctx.dispatch(new HandleErrors(error)); }));
    };
    ApplicationsState.prototype.handleErrors = function (ctx, _a) {
        var error = _a.error;
        // tslint:disable no-small-switch
        switch (error.status) {
            // The Job doesn't exist so => 404
            case 404: {
                console.error('Not found');
                ctx.patchState({ error: error });
                return ctx.dispatch(new Navigate([buildUrl(FeaturesRoutingEnum.NotFound)], undefined, { skipLocationChange: true }));
            }
            default: {
                console.error("Code " + error.status + " => " + error.statusText);
                this.notifService.createToast('Une erreur est survenue', NotifStatus.Failed);
                return ctx.patchState({ error: error });
            }
        }
        // tslint:enable no-small-switch
    };
    var ApplicationsState_1;
    __decorate([
        Action(InitStaticData),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], ApplicationsState.prototype, "initStaticData", null);
    __decorate([
        Action(SetApplication),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, SetApplication]),
        __metadata("design:returntype", void 0)
    ], ApplicationsState.prototype, "setApplication", null);
    __decorate([
        Action(FindApplications),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, FindApplications]),
        __metadata("design:returntype", void 0)
    ], ApplicationsState.prototype, "getApplications", null);
    __decorate([
        Action(HandleErrors),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, HandleErrors]),
        __metadata("design:returntype", void 0)
    ], ApplicationsState.prototype, "handleErrors", null);
    __decorate([
        Selector(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [ApplicationStateModel]),
        __metadata("design:returntype", void 0)
    ], ApplicationsState, "status", null);
    __decorate([
        Selector(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [ApplicationStateModel]),
        __metadata("design:returntype", void 0)
    ], ApplicationsState, "sourcesPlatforms", null);
    ApplicationsState = ApplicationsState_1 = __decorate([
        State({
            name: 'applications',
            defaults: defaultState,
        }),
        __metadata("design:paramtypes", [ApplicationWebservice,
            NotificationsService])
    ], ApplicationsState);
    return ApplicationsState;
}());
export { ApplicationsState };
