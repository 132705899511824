var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { HttpClient } from '@angular/common/http';
import { toJob } from '@ats/models';
import { environment } from '@env/environment';
import { JobResource } from '@models/job/job.resource';
import { buildUrl } from '@wizbii/angular-utilities';
import * as dayJs from 'dayjs';
import { map } from 'rxjs/operators';
var JobWebservice = /** @class */ (function () {
    function JobWebservice(http) {
        this.http = http;
        this.baseUrl = environment.api.job + "/v1/jobs/?:id";
    }
    JobWebservice.prototype.createJob = function (job) {
        var url = buildUrl(this.baseUrl, {});
        return this.http.post(url, this.transformJobForDate(job), { headers: { ignoreLoadingBar: '' } }).pipe(map(toJob));
    };
    JobWebservice.prototype.get = function (id) {
        var url = buildUrl(this.baseUrl, { id: id });
        return this.http.get(url).pipe(map(toJob));
    };
    JobWebservice.prototype.getAll = function () {
        var url = buildUrl(this.baseUrl, {});
        return this.http.get(url).pipe(map(function (jobs) { return jobs.map(toJob); }));
    };
    JobWebservice.prototype.updateJob = function (job) {
        var url = buildUrl(this.baseUrl, { id: job._id });
        return this.http
            .put(url, this.transformJobForDate(job), {
            headers: { ignoreLoadingBar: '' },
        })
            .pipe(map(function () { return job; }), map(toJob));
    };
    JobWebservice.prototype.transformJobForDate = function (job) {
        return new JobResource(__assign({}, job, { startDate: dayJs(job.startDate).format() }));
    };
    JobWebservice.prototype.getDomains = function () {
        return this.http.get(environment.api.job + "/v1/domains", { headers: { ignoreLoadingBar: '' } });
    };
    JobWebservice.prototype.getContracts = function () {
        return this.http.get(environment.api.job + "/v1/contracts", { headers: { ignoreLoadingBar: '' } });
    };
    JobWebservice.prototype.getDurations = function () {
        return this.http.get(environment.api.job + "/v1/durations", { headers: { ignoreLoadingBar: '' } });
    };
    JobWebservice.prototype.getStudyLevels = function () {
        return this.http.get(environment.api.job + "/v1/study-levels", { headers: { ignoreLoadingBar: '' } });
    };
    JobWebservice.prototype.getExperiences = function () {
        return this.http.get(environment.api.job + "/v1/experiences", { headers: { ignoreLoadingBar: '' } });
    };
    JobWebservice.prototype.getStatus = function () {
        return this.http.get(environment.api.job + "/v1/status", { headers: { ignoreLoadingBar: '' } });
    };
    return JobWebservice;
}());
export { JobWebservice };
