import { PopupComponent } from '@commons/popup/popup/popup.component';
var PopupItemDirective = /** @class */ (function () {
    function PopupItemDirective(parent) {
        this.parent = parent;
        this.popupRole = 'menu';
    }
    Object.defineProperty(PopupItemDirective.prototype, "id", {
        get: function () {
            return this.parent.id + "__menu";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PopupItemDirective.prototype, "triggerId", {
        get: function () {
            return this.parent.id + "__trigger";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PopupItemDirective.prototype, "hidden", {
        get: function () {
            return !this.parent.opened;
        },
        enumerable: true,
        configurable: true
    });
    PopupItemDirective.prototype.onClick = function () {
        if (this.parent.autoClose && this.parent.opened) {
            this.parent.close();
        }
    };
    return PopupItemDirective;
}());
export { PopupItemDirective };
