/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./main.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/progress-bar/typings/index.ngfactory";
import * as i3 from "@angular/material/progress-bar";
import * as i4 from "@angular/platform-browser/animations";
import * as i5 from "@angular/common";
import * as i6 from "@angular/router";
import * as i7 from "../sprite/sprite.component.ngfactory";
import * as i8 from "../sprite/sprite.component";
import * as i9 from "../../../commons/notifications/notifications.component.ngfactory";
import * as i10 from "../../../commons/notifications/notifications.component";
import * as i11 from "../../../commons/notifications/notifications.service";
import * as i12 from "@angular/platform-browser";
import * as i13 from "./main.component";
import * as i14 from "@ngxs/store";
import * as i15 from "@ngx-loading-bar/core";
import * as i16 from "../../services/router-extension.service";
import * as i17 from "../../services/global.service";
var styles_MainComponent = [i0.styles];
var RenderType_MainComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MainComponent, data: {} });
export { RenderType_MainComponent as RenderType_MainComponent };
function View_MainComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-progress-bar", [["aria-valuemax", "100"], ["aria-valuemin", "0"], ["class", "mat-progress-bar--only-progress mat-progress-bar--fixed-top mat-progress-bar"], ["mode", "determinate"], ["role", "progressbar"]], [[1, "aria-valuenow", 0], [1, "mode", 0], [2, "_mat-animation-noopable", null]], null, null, i2.View_MatProgressBar_0, i2.RenderType_MatProgressBar)), i1.ɵdid(1, 4374528, null, 0, i3.MatProgressBar, [i1.ElementRef, i1.NgZone, [2, i4.ANIMATION_MODULE_TYPE], [2, i3.MAT_PROGRESS_BAR_LOCATION]], { value: [0, "value"], mode: [1, "mode"] }, null)], function (_ck, _v) { var currVal_3 = _v.parent.context.ngIf; var currVal_4 = "determinate"; _ck(_v, 1, 0, currVal_3, currVal_4); }, function (_ck, _v) { var currVal_0 = (((i1.ɵnov(_v, 1).mode === "indeterminate") || (i1.ɵnov(_v, 1).mode === "query")) ? null : i1.ɵnov(_v, 1).value); var currVal_1 = i1.ɵnov(_v, 1).mode; var currVal_2 = i1.ɵnov(_v, 1)._isNoopAnimation; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_MainComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MainComponent_2)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = (_v.context.ngIf > 0); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_MainComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 2, null, View_MainComponent_1)), i1.ɵdid(1, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(3, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(4, 212992, null, 0, i6.RouterOutlet, [i6.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "app-sprite", [], null, null, null, i7.View_SpriteComponent_0, i7.RenderType_SpriteComponent)), i1.ɵdid(6, 49152, null, 0, i8.SpriteComponent, [], null, null), (_l()(), i1.ɵeld(7, 0, null, null, 1, "app-notifications", [], null, null, null, i9.View_NotificationsComponent_0, i9.RenderType_NotificationsComponent)), i1.ɵdid(8, 245760, null, 0, i10.NotificationsComponent, [i11.NotificationsService, i1.ChangeDetectorRef, i12.DomSanitizer], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.loader.progress$)); _ck(_v, 1, 0, currVal_0); _ck(_v, 4, 0); _ck(_v, 8, 0); }, null); }
export function View_MainComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-main", [], [[2, "hover-on", null], [2, "hover-off", null], [2, "focus-off", null]], [[null, "pointerenter"], [null, "keydown.Tab"]], function (_v, en, $event) { var ad = true; if (("pointerenter" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onPointerEnter($event) !== false);
        ad = (pd_0 && ad);
    } if (("keydown.Tab" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onTabKeyDown() !== false);
        ad = (pd_1 && ad);
    } return ad; }, View_MainComponent_0, RenderType_MainComponent)), i1.ɵdid(1, 245760, null, 0, i13.MainComponent, [i14.Store, i15.LoadingBarService, i5.DOCUMENT, i1.PLATFORM_ID, i1.LOCALE_ID, i1.ErrorHandler, i16.RouterExtensionService, i17.GlobalService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).hoverEnabled; var currVal_1 = i1.ɵnov(_v, 1).hoverDisabled; var currVal_2 = i1.ɵnov(_v, 1).focusOutlineDisabled; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
var MainComponentNgFactory = i1.ɵccf("app-main", i13.MainComponent, View_MainComponent_Host_0, {}, {}, []);
export { MainComponentNgFactory as MainComponentNgFactory };
