export enum FeaturesRoutingEnum {
  Root = '',
  SignIn = 'sign-in',
  SignUp = 'sign-up',
  Wizbii = 'wizbii',
  LostPassword = 'lost-password',
  Jobs = 'jobs',
  Contributors = 'contributors',
  Company = 'company',
  Candidates = 'candidates',
  Profile = 'profile',
  Parameters = 'parameters',
  Confidentiality = 'confidentiality',

  NotFound = '404',
  NotAuthorized = 'not-authorized',
}
