import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'app-popup',
  template: '<ng-content></ng-content>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopupComponent {
  private static COUNT = 0;

  @Input() opened = false;
  @Output() openedChange = new EventEmitter<boolean>();

  @Input() autoClose = true;

  id = `app-popup-${PopupComponent.COUNT}`;

  constructor(private readonly host: ElementRef) {
    PopupComponent.COUNT += 1;
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(evt: MouseEvent) {
    if (this.opened && !this.host.nativeElement.contains(evt.target)) {
      this.close();
    }
  }

  @HostListener('document:keydown.escape')
  onDocumentEscape() {
    if (this.opened) {
      this.close();
    }
  }

  toggle(): void {
    this.opened = !this.opened;
    this.openedChange.emit(this.opened);
  }

  close(): void {
    this.opened = false;
    this.openedChange.emit(false);
  }
}
