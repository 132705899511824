import { InjectionToken } from '@angular/core';
import { ContentLegalWebservice } from '@wizbii/webservices';
import { from } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
export var CONSENT_API_CONFIG = new InjectionToken('CONSENT_API_CONFIG');
var ConsentWebservice = /** @class */ (function () {
    function ConsentWebservice(window, _a, contentLegalWebservice) {
        var appId = _a.appId;
        this.window = window;
        this.contentLegalWebservice = contentLegalWebservice;
        this.appId = appId;
    }
    ConsentWebservice.prototype.sendLastVersionsConsent = function (value, type, appId) {
        var _this = this;
        return this.contentLegalWebservice.getVersionsByServiceAndType(appId ? appId : this.appId, type).pipe(switchMap(function (version) {
            return from(_this.window.WizbiiGdpr.saveConsent({
                key: type,
                value: value,
                lastVersion: version && version.lastVersion ? version.lastVersion : null,
            }, appId ? appId : _this.appId));
        }), map(function (consent) { return consent; }));
    };
    return ConsentWebservice;
}());
export { ConsentWebservice };
