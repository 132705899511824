import { version } from '@env/version';
import { LocaleEnum } from '@models/commons/locales.enum';
var env = {};
if (typeof window === 'object') {
    env = window['env'] || {};
}
else if (typeof global === 'object') {
    env = global['ANGULAR_ENV'] || {};
}
var platform = env.PLATFORM || 'prod';
var wizbiiDomain = env.WIZBII_DOMAIN || 'wizbii.com';
export var atsDomain = env.ATS_DOMAIN || 'atsii.fr';
export var atsPublicDomain = "www." + atsDomain;
var locale = LocaleEnum[process.env.LOCALE] || LocaleEnum.fr;
var ɵ0 = env.HOTJAR_ID || '1258167';
export var environment = {
    applicationId: 'ats-app',
    version: version,
    atsPublicDomain: atsPublicDomain,
    wizbiiDomain: wizbiiDomain,
    atsDomain: atsDomain,
    bugsnagId: '09805fab276e14e70edf4b9ccc7b1369',
    bugsnagSsrId: '30a68dfaae9b508b31dcc07f1bb89a70',
    prod: platform === 'prod',
    qa3: platform === 'qa3',
    qa4: platform === 'qa4',
    dev: platform === 'dev',
    name: platform,
    locale: locale,
    api: {
        account: "https://account.api." + wizbiiDomain,
        authentication: "https://auth." + wizbiiDomain,
        candidate: "https://candidate.api." + atsDomain,
        consent: "https://consent." + wizbiiDomain,
        contact: "https://contact.api." + wizbiiDomain,
        content: "https://content-api." + wizbiiDomain,
        file: "https://file." + wizbiiDomain,
        googleStorage: 'https://storage.googleapis.com',
        imaginary: "https://imaginary." + wizbiiDomain,
        images: "https://images." + wizbiiDomain,
        job: "https://job.api." + atsDomain,
        media: "https://m-api." + wizbiiDomain,
        place: "https://place." + wizbiiDomain,
        recruiter: "https://recruiter.api." + atsDomain,
        wizbiiAnalytiks: "https://a." + atsDomain,
        monitor: "https://monitor.api." + wizbiiDomain,
    },
    wizbiiAnalytiksUserId: env.WIZBII_ANALYTICS_UAI || 'UA-22851031-38',
    googleAnalyticsUserId: env.GOOGLE_ANALYTICS_UAI || 'UA-22851031-38',
    wizbiiFiles: env.WIZBII_FILES_GCS_BUCKET || 'wizbii-files',
    gdprWidgetUrl: "https://storage.googleapis.com/wizbii/consent/" + platform + "/widget/bundle.js?v=" + Date.now(),
    hotjar: {
        id: ɵ0,
        version: '6',
    },
    facebookPixel: {
        appId: env.FACEBOOK_PIXEL_ID || '607776016412150',
        version: '2.0',
    },
};
export { ɵ0 };
