import { DOCUMENT } from '@angular/common';
import { Inject } from '@angular/core';
import { Action, createSelector, NgxsAfterBootstrap, State, StateContext } from '@ngxs/store';
import { Consent, SetConsent, SetConsents } from '@store/consents/consents.actions';
import { fromEvent } from 'rxjs';

export type ConsentsStateModel = Record<string, boolean>;

@State<ConsentsStateModel>({
  name: 'consents',
  defaults: {},
})
export class ConsentsState implements NgxsAfterBootstrap {
  static consent(key: string) {
    return createSelector(
      [ConsentsState],
      (state: ConsentsStateModel) => state[key]
    );
  }

  constructor(@Inject(DOCUMENT) private readonly document: any) {}

  ngxsAfterBootstrap(ctx?: StateContext<any>): void {
    fromEvent(this.document, 'WizbiiGdpr.consentsChange').subscribe(({ WizbiiGdpr: { consent } }) => {
      ctx.dispatch(new SetConsents(consent));
    });
    // Listen for a consent changed on its own
    fromEvent(this.document, 'WizbiiGdpr.consentChange').subscribe(({ WizbiiGdpr: { consent } }) => {
      ctx.dispatch(new SetConsent(consent));
    });
  }

  @Action(SetConsents)
  setConsents(ctx: StateContext<ConsentsStateModel>, { consents }: SetConsents) {
    ctx.setState(
      [].concat(...Object.values(consents)).reduce((acc: ConsentsStateModel, current: Consent) => {
        acc[current.key] = current.value;
        return acc;
      }, {})
    );
  }

  @Action(SetConsent)
  setConsent(ctx: StateContext<ConsentsStateModel>, { consent }: SetConsent) {
    ctx.patchState({ [consent.key]: consent.value });
  }
}
