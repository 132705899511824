import { ContractEnum, DomainEnum, DurationEnum, ExperienceEnum, JobStatusEnum, StudyLevelEnum } from '@ats/models';
import { City } from '@wizbii/models';

export interface JobResourceInterface {
  title: string;
  description: string;
  city: City;
  domain: DomainEnum;
  contract: ContractEnum;
  duration: DurationEnum;
  hour?: number;
  startDate: string;
  salaryMin?: number;
  salaryMax?: number;
  rqth: boolean;
  remote: boolean;
  cadre: boolean;
  profileDescription: string;
  profileStudyLevel: StudyLevelEnum;
  profileExperience: ExperienceEnum;
  skills?: string[];
  status: JobStatusEnum;
  multicast: boolean;
}

export class JobResource implements JobResourceInterface {
  cadre: boolean;
  city: City;
  contract: ContractEnum;
  description: string;
  domain: DomainEnum;
  duration: DurationEnum;
  hour?: number;
  profileDescription: string;
  profileExperience: ExperienceEnum;
  profileStudyLevel: StudyLevelEnum;
  remote: boolean;
  rqth: boolean;
  salaryMin?: number;
  salaryMax?: number;
  skills?: string[];
  startDate: string;
  status: JobStatusEnum;
  title: string;
  multicast: boolean;

  constructor(data: any = {}) {
    this.title = data.title;
    this.description = data.description;
    this.city = data.city ? new City(data.city) : undefined;
    this.domain = data.domain;
    this.contract = data.contract;
    this.duration = data.duration;
    this.hour = Number(data.hour);
    this.startDate = data.startDate;
    this.salaryMax = data.salaryMax;
    this.salaryMin = data.salaryMin;
    this.rqth = data.rqth;
    this.remote = data.remote;
    this.cadre = data.cadre;
    this.profileDescription = data.profileDescription;
    this.profileStudyLevel = data.profileStudyLevel;
    this.profileExperience = data.profileExperience;
    this.skills = data.skills;
    this.status = data.status;
    this.multicast = data.multicast;
  }
}
