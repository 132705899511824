import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
/**
 * Provide access to the router's previous URL, for instance, to distinguish initial from subsequent navigations.
 * https://stackoverflow.com/a/48866813/758806
 */
var RouterExtensionService = /** @class */ (function () {
    function RouterExtensionService(router) {
        var _this = this;
        this.router = router;
        this.router.events.pipe(filter(function (event) { return event instanceof NavigationEnd; })).subscribe(function (event) {
            _this.previousUrl = _this.currentUrl;
            _this.currentUrl = event.url;
        });
    }
    RouterExtensionService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RouterExtensionService_Factory() { return new RouterExtensionService(i0.ɵɵinject(i1.Router)); }, token: RouterExtensionService, providedIn: "root" });
    return RouterExtensionService;
}());
export { RouterExtensionService };
