import { environment } from '@env/environment';
var ɵ0 = {
    appId: environment.applicationId,
    baseUrl: environment.api.authentication,
}, ɵ1 = {
    baseUrl: environment.api.file,
    googleStorageUrl: environment.api.googleStorage,
    wizbiiFilesBucket: environment.wizbiiFiles,
}, ɵ2 = {
    appId: environment.applicationId,
    baseUrl: environment.api.contact,
}, ɵ3 = {
    baseUrl: environment.api.place,
}, ɵ4 = {
    baseUrl: environment.api.recruiter,
}, ɵ5 = {
    baseUrl: environment.api.candidate,
}, ɵ6 = {
    baseUrl: environment.api.content,
    locale: environment.locale,
}, ɵ7 = {
    appId: environment.applicationId,
    baseUrl: environment.api.consent,
};
var FeaturesModule = /** @class */ (function () {
    function FeaturesModule() {
    }
    return FeaturesModule;
}());
export { FeaturesModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7 };
