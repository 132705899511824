import { animate, state, style, transition, trigger } from '@angular/animations';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  SecurityContext,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Notif } from '@commons/notifications/notif';
import { NotifStatus } from '@commons/notifications/notif-status.enum';
import { NotificationsService } from '@commons/notifications/notifications.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('animateToasts', [
      state('void', style({ opacity: 0 })),
      transition(':enter, :leave', [
        // void <=> *
        animate('0.3s ease'),
      ]),
    ]),
  ],
})
export class NotificationsComponent implements OnInit, OnDestroy {
  private readonly componentDestroyed$: Subject<boolean> = new Subject();

  notifications: Notif[] = [];
  NotifStatus = NotifStatus;
  duration = 5000;

  constructor(
    readonly notificationService: NotificationsService,
    readonly cdr: ChangeDetectorRef,
    readonly domSanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.notificationService.notification.pipe(takeUntil(this.componentDestroyed$)).subscribe((notification: Notif) => {
      this.updateNotification(notification);
    });

    this.notificationService.clearToasts.pipe(takeUntil(this.componentDestroyed$)).subscribe(() => {
      this.clearToasts();
    });

    this.notificationService.clearLastToast.pipe(takeUntil(this.componentDestroyed$)).subscribe(() => {
      this.clearLastToast();
    });
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }

  trackById(_, value: Notif): string {
    return value.id;
  }

  private updateNotification(notification: Notif) {
    notification.message = this.domSanitizer.sanitize(SecurityContext.HTML, notification.message);

    this.notifications.unshift(notification);
    this.cdr.detectChanges();

    setTimeout(() => {
      const index = this.notifications.indexOf(notification);
      this.notifications.splice(index, 1);
      this.cdr.detectChanges();
    }, notification.duration || this.duration);
  }

  removeNotification(index: number) {
    this.notifications.splice(index, 1);
    this.cdr.detectChanges();
  }

  private clearLastToast() {
    this.notifications.pop();
    this.cdr.detectChanges();
  }

  private clearToasts() {
    this.notifications = [];
    this.cdr.detectChanges();
  }
}
