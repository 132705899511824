import { ChangeDetectionStrategy, Component, HostBinding, Inject, Input } from '@angular/core';
import { environment } from '@env/environment';
import { ImageService, WINDOW } from '@wizbii/angular-utilities';

export interface LogoInterface {
  logoUrl?: string | null;
  lastName?: string | null;
  firstName?: string | null;
}

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoComponent {
  text: string;
  imageSize: number;

  @Input() background = '#8782ff';
  @Input() size: 'samll' | 'medium' | 'large' = 'medium';
  @HostBinding('attr.aria-hidden') ariaHidden = true;
  @HostBinding('style.backgroundColor') backgroundColor = '#fff';
  @HostBinding('style.color') @Input() color = '#ffffff';
  @HostBinding('style.backgroundImage') backgroundImage = '';
  @HostBinding('class.fallback') fallback: boolean;

  @Input()
  set item(item: LogoInterface) {
    if (item.logoUrl) {
      this.imageSize = this.computeImageSize();
      const logoUrl = this.getLogoUrl(item);
      const resizedLogoUrl = this.imageService.resize(logoUrl, { width: this.imageSize });
      this.backgroundImage = `url(${resizedLogoUrl})`;
      this.backgroundColor = '#fff';
      this.text = undefined;
    } else {
      this.backgroundImage = undefined;
      this.backgroundColor = this.background;
      this.text = `${item.firstName ? item.firstName[0] : '-'}${item.lastName ? item.lastName[0] : '-'}`;
      this.fallback = true;
    }
  }

  constructor(@Inject(WINDOW) private readonly window: any, private readonly imageService: ImageService) {}

  private getLogoUrl(item: any): string {
    return item.logoUrl.startsWith('http')
      ? item.logoUrl
      : `${environment.api.googleStorage}/${environment.wizbiiFiles}/${item.logoUrl}`;
  }

  private computeImageSize(): number {
    let size = this.size === 'medium' ? 80 : 110;

    if (this.window.devicePixelRatio >= 2) {
      size = size * 2;
    }

    return size;
  }
}
