import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Job, toJob } from '@ats/models';
import { environment } from '@env/environment';
import { JobResource } from '@models/job/job.resource';
import { buildUrl } from '@wizbii/angular-utilities';
import * as dayJs from 'dayjs';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class JobWebservice {
  private readonly baseUrl = `${environment.api.job}/v1/jobs/?:id`;

  constructor(private readonly http: HttpClient) {}

  createJob(job: Job): Observable<Job> {
    const url = buildUrl(this.baseUrl, {});

    return this.http.post(url, this.transformJobForDate(job), { headers: { ignoreLoadingBar: '' } }).pipe(map(toJob));
  }

  get(id: string): Observable<Job> {
    const url = buildUrl(this.baseUrl, { id });

    return this.http.get<object>(url).pipe(map(toJob));
  }

  getAll(): Observable<Job[]> {
    const url = buildUrl(this.baseUrl, {});

    return this.http.get<object[]>(url).pipe(map(jobs => jobs.map(toJob)));
  }

  updateJob(job: Job): Observable<Job> {
    const url = buildUrl(this.baseUrl, { id: job._id });

    return this.http
      .put<void>(url, this.transformJobForDate(job), {
        headers: { ignoreLoadingBar: '' },
      })
      .pipe(
        map(() => job),
        map(toJob)
      );
  }

  private transformJobForDate(job: Job): JobResource {
    return new JobResource({ ...job, startDate: dayJs(job.startDate).format() });
  }

  getDomains(): Observable<string[]> {
    return this.http.get<string[]>(`${environment.api.job}/v1/domains`, { headers: { ignoreLoadingBar: '' } });
  }

  getContracts(): Observable<string[]> {
    return this.http.get<string[]>(`${environment.api.job}/v1/contracts`, { headers: { ignoreLoadingBar: '' } });
  }

  getDurations(): Observable<string[]> {
    return this.http.get<string[]>(`${environment.api.job}/v1/durations`, { headers: { ignoreLoadingBar: '' } });
  }

  getStudyLevels(): Observable<string[]> {
    return this.http.get<string[]>(`${environment.api.job}/v1/study-levels`, { headers: { ignoreLoadingBar: '' } });
  }

  getExperiences(): Observable<string[]> {
    return this.http.get<string[]>(`${environment.api.job}/v1/experiences`, { headers: { ignoreLoadingBar: '' } });
  }

  getStatus(): Observable<string[]> {
    return this.http.get<string[]>(`${environment.api.job}/v1/status`, { headers: { ignoreLoadingBar: '' } });
  }
}
