import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { FeaturesRoutingEnum } from '@features/features-routing.enum';
import { Store } from '@ngxs/store';
import { SessionState } from '@store/session/session.state';
import { buildPath } from '@wizbii/angular-utilities';
import { Observable } from 'rxjs';
import { filter, first, map, switchMap } from 'rxjs/operators';

@Injectable()
export class IsAlreadyConnectedGuard implements CanActivate {
  constructor(private readonly router: Router, private readonly store: Store) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this.store.select(SessionState.isInitialized).pipe(
      filter(isInitialized => isInitialized),
      first(),
      switchMap(() => this.store.selectOnce(SessionState.tokens)),
      map(tokens => !!tokens),
      map(isLogged => {
        if (isLogged) {
          return this.router.parseUrl(buildPath(FeaturesRoutingEnum.Jobs));
        }

        return !isLogged;
      })
    );
  }
}
