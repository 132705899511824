export enum LangEnum {
  fr = 'fr',
}

export enum LocaleEnum {
  fr = 'fr_FR',
}

export const Locales: Record<LangEnum, LocaleEnum> = {
  [LangEnum.fr]: LocaleEnum.fr,
};
