import { Inject, Injectable, InjectionToken } from '@angular/core';
import { WINDOW } from '@wizbii/angular-utilities';
import { ArticleTypes } from '@wizbii/models';
import { ContentLegalWebservice } from '@wizbii/webservices';
import { from, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

export interface ConsentApiConfig {
  appId: string;
  baseUrl: string;
}

export interface Consent {
  key: string;
  value: boolean;
  label?: string;
  versions?: any;
}

export const CONSENT_API_CONFIG = new InjectionToken<ConsentApiConfig>('CONSENT_API_CONFIG');

@Injectable()
export class ConsentWebservice {
  private readonly appId: string;

  constructor(
    @Inject(WINDOW) private readonly window: any,
    @Inject(CONSENT_API_CONFIG) { appId }: ConsentApiConfig,
    private readonly contentLegalWebservice: ContentLegalWebservice
  ) {
    this.appId = appId;
  }

  sendLastVersionsConsent(value: boolean, type: ArticleTypes, appId?: string): Observable<Consent> {
    return this.contentLegalWebservice.getVersionsByServiceAndType(appId ? appId : this.appId, type).pipe(
      switchMap(version =>
        from(
          this.window.WizbiiGdpr.saveConsent(
            {
              key: type,
              value,
              lastVersion: version && version.lastVersion ? version.lastVersion : null,
            },
            appId ? appId : this.appId
          )
        )
      ),
      map(consent => consent as Consent)
    );
  }
}
