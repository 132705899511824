import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Inject, PLATFORM_ID } from '@angular/core';
import { GlobalService } from '@core/services/global.service';
import { UserOverview } from '@models/auth/user-overview.interface';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { SetAuthenticatedRecruiter } from '@store/recruiters/recruiters.actions';
import {
  Init,
  InitUser,
  Logout,
  RecoverPassword,
  RecoverPasswordFailed,
  RecoverPasswordSuccess,
  RefreshTokensStore,
  RemoveTokens,
  SetTokens,
  SignIn,
  SignInFailed,
  SignInSuccess,
  SignUp,
  SignUpFailed,
  SignUpWizbii,
  UpdateUser,
} from '@store/session/session.actions';
import { AccountApiWebservice } from '@webservices/account-api/account-api.webservice';
import { DataStorageService, deserializeJwt, JwtTokens } from '@wizbii/angular-utilities';
import { AuthenticationWebservice } from '@wizbii/webservices';
import { CookieService } from 'ngx-cookie-service';
import { Observable, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

export enum SessionStateEnum {
  Init = 'INIT',
  Logged = 'LOGGED',
  LoggingIn = 'LOGGING_IN',
  SigningUpIn = 'SIGNING_UP_IN',
  Recovering = 'RECOVERING',
  Recovered = 'RECOVERED',
  NotLogged = 'NOT_LOGGED',
}

export class SessionStateModel {
  tokens: JwtTokens;
  loading: boolean;
  state: SessionStateEnum;
  error: HttpErrorResponse | Error;
  errorMessage: string;
  user: UserOverview;
}

export enum SessionErrorMessage {
  badCredential = 'BadCredentialsException',
  emailAlreadyUsed = 'EmailAlreadyUsedException',
  invitationAlreadyExist = 'InvitationAlreadyExist',
  recruiterAlreadyExists = 'RecruiterAlreadyExists',
}

const defaultState: SessionStateModel = {
  tokens: null,
  loading: false,
  state: SessionStateEnum.Init,
  error: null,
  errorMessage: null,
  user: null,
};

@State<SessionStateModel>({
  name: 'session',
  defaults: defaultState,
})
export class SessionState {
  // tslint:disable:no-identical-functions

  @Selector()
  static tokens(state: SessionStateModel) {
    return state.tokens;
  }

  @Selector()
  static hasTokens(state: SessionStateModel) {
    return !!state.tokens;
  }

  @Selector()
  static user(state: SessionStateModel) {
    return state.user;
  }

  @Selector()
  static state(state: SessionStateModel) {
    return state.state;
  }

  @Selector()
  static isInitialized(state: SessionStateModel) {
    return state.state !== SessionStateEnum.Init;
  }

  @Selector()
  static isLogged(state: SessionStateModel) {
    return state.state === SessionStateEnum.Logged;
  }

  @Selector()
  static failed(state: SessionStateModel) {
    return state.state === SessionStateEnum.NotLogged;
  }

  @Selector()
  static recovered(state: SessionStateModel) {
    return state.state === SessionStateEnum.Recovered;
  }

  @Selector()
  static loading(state: SessionStateModel) {
    return state.loading;
  }

  @Selector()
  static error(state: SessionStateModel) {
    return state.error;
  }

  @Selector()
  static errorMessage(state: SessionStateModel) {
    return state.errorMessage;
  }

  constructor(
    private readonly dataStorageService: DataStorageService,
    private readonly authService: AuthenticationWebservice,
    private readonly accountApiWebservice: AccountApiWebservice,
    private readonly cookieService: CookieService,
    private readonly globalService: GlobalService,
    @Inject(PLATFORM_ID) private readonly platformId: Object,
    @Inject(DOCUMENT) private readonly document: any
  ) {}

  @Action(Init)
  init(ctx: StateContext<SessionStateModel>, { tokens }: Init) {
    const realTokens = tokens ? tokens : this.readTokens();

    if (!!realTokens) {
      this.globalService.init(realTokens.token, realTokens.refreshToken);
      ctx.patchState({ tokens: realTokens });

      const userId = deserializeJwt(realTokens.token)['user-id'];

      return ctx.dispatch([new InitUser(userId), new SetAuthenticatedRecruiter(userId)]).pipe(
        switchMap(() => of(ctx.patchState({ state: SessionStateEnum.Logged }))),
        catchError(() => of(ctx.patchState({ state: SessionStateEnum.NotLogged })))
      );
    }

    return ctx.patchState({ state: SessionStateEnum.NotLogged });
  }

  @Action(InitUser)
  initUser(ctx: StateContext<SessionStateModel>, { id }: InitUser) {
    return this.authService.getUserOverview(id).pipe(switchMap(user => of(ctx.patchState({ user }))));
  }

  @Action(UpdateUser)
  updateUser(ctx: StateContext<SessionStateModel>, { user }: UpdateUser) {
    return ctx.patchState({ user });
  }

  @Action(RefreshTokensStore)
  refreshTokensStore(ctx: StateContext<SessionStateModel>, action: RefreshTokensStore) {
    const { tokens } = action;

    return ctx.patchState({
      tokens,
    });
  }

  @Action(SignIn)
  signIn(ctx: StateContext<SessionStateModel>, { username, password }: SignIn): Observable<void> {
    // Prevent parallel requests
    if (this.isLoading(ctx)) {
      return of(undefined);
    }

    ctx.patchState({ error: null, errorMessage: null, loading: true, state: SessionStateEnum.LoggingIn });

    return this.authService.signIn(username, password, { headers: { ignoreLoadingBar: '' } }).pipe(
      switchMap(tokens => ctx.dispatch(new SignInSuccess(tokens))),
      catchError(error => ctx.dispatch(new SignInFailed(error)))
    );
  }

  @Action(SignInSuccess)
  signInSuccess(ctx: StateContext<SessionStateModel>, { tokens }: SignInSuccess): Observable<void> {
    this.writeTokens(tokens);

    ctx.patchState({
      loading: false,
      state: SessionStateEnum.Logged,
      tokens,
    });

    return ctx.dispatch(new Init(tokens));
  }

  @Action(SignInFailed)
  signInFailed(ctx: StateContext<SessionStateModel>, { error }: SignInFailed): Observable<void> {
    this.forgetTokens();

    // Retrieve best error message (note that `HTTPErrorResponse` inherits from `Error`)
    const errorMessage = (error instanceof HttpErrorResponse && error.error && error.error.type) || error.message;

    ctx.patchState({
      tokens: null,
      state: SessionStateEnum.NotLogged,
      loading: false,
      error,
      errorMessage,
    });

    if (errorMessage === SessionErrorMessage.badCredential) {
      console.warn(errorMessage);
      return of(undefined);
    }

    return throwError(error); // re-throw unhandled errors
  }

  @Action(SignUp)
  signup(
    ctx: StateContext<SessionStateModel>,
    { firstname, lastname, company, email, password, phone, invitationToken }: SignUp
  ) {
    // Prevent parallel requests
    if (this.isLoading(ctx)) {
      return of(undefined);
    }

    ctx.patchState({ error: null, errorMessage: null, loading: true, state: SessionStateEnum.SigningUpIn });

    return this.accountApiWebservice
      .create(
        {
          email,
          firstName: firstname,
          lastName: lastname,
          phone,
        },
        {
          name: company,
        },
        {
          username: email,
          firstName: firstname,
          lastName: lastname,
          password,
          phone,
        },
        invitationToken
      )
      .pipe(
        switchMap(tokens => ctx.dispatch(new SignInSuccess(tokens))),
        catchError(error => ctx.dispatch(new SignUpFailed(error)))
      );
  }

  @Action(SignUpWizbii)
  signupWizbii(
    ctx: StateContext<SessionStateModel>,
    { email, firstname, lastname, company, phone, invitationToken }: SignUpWizbii
  ) {
    // Prevent parallel requests
    if (this.isLoading(ctx)) {
      return of(undefined);
    }

    ctx.patchState({ error: null, errorMessage: null, loading: true, state: SessionStateEnum.SigningUpIn });

    return this.accountApiWebservice
      .create(
        {
          email,
          firstName: firstname,
          lastName: lastname,
          phone,
        },
        {
          name: company,
        },
        null,
        invitationToken
      )
      .pipe(
        switchMap(tokens => ctx.dispatch(new SignInSuccess(tokens))),
        catchError(error => ctx.dispatch(new SignUpFailed(error)))
      );
  }

  @Action(SignUpFailed)
  failed(ctx: StateContext<SessionStateModel>, action: SignUpFailed) {
    const { error } = action;
    const errorMessage = (error instanceof HttpErrorResponse && error.error && error.error.type) || error.message;

    this.forgetTokens();

    return ctx.patchState({
      error,
      errorMessage,
      tokens: null,
      state: SessionStateEnum.NotLogged,
      loading: false,
    });
  }

  @Action(RecoverPassword)
  recoverPassword(ctx: StateContext<SessionStateModel>, action: RecoverPassword) {
    const { email } = action;

    ctx.patchState({ error: null, errorMessage: null, loading: true, state: SessionStateEnum.Recovering });

    return this.authService.getRecoveryEmail(email, { headers: { ignoreLoadingBar: '' } }).pipe(
      switchMap(() => ctx.dispatch(new RecoverPasswordSuccess())),
      catchError(error => ctx.dispatch(new RecoverPasswordFailed(error)))
    );
  }

  @Action(RecoverPasswordSuccess)
  recoverPasswordSuccess(ctx: StateContext<SessionStateModel>) {
    return ctx.patchState({
      loading: false,
      state: SessionStateEnum.Recovered,
    });
  }

  @Action(RecoverPasswordFailed)
  recoverPasswordFailed(ctx: StateContext<SessionStateModel>, action: RecoverPasswordFailed) {
    const { error } = action;
    const errorMessage = (error instanceof HttpErrorResponse && error.error && error.error.type) || error.message;

    this.forgetTokens();

    return ctx.patchState({
      error,
      errorMessage,
      tokens: null,
      state: SessionStateEnum.NotLogged,
      loading: false,
    });
  }

  @Action(SetTokens)
  setTokens(ctx: StateContext<SessionStateModel>, action: SetTokens) {
    const { tokens } = action;

    this.writeTokens(tokens);

    return ctx.patchState({
      tokens,
    });
  }

  @Action(RemoveTokens)
  removeTokens(ctx: StateContext<SessionStateModel>) {
    this.forgetTokens();

    return ctx.patchState({
      tokens: null,
    });
  }

  @Action(Logout)
  logout(ctx: StateContext<SessionStateModel>) {
    this.forgetTokens();

    if (isPlatformBrowser(this.platformId)) {
      this.document.location = this.document.location.origin;
    }

    return ctx.setState(defaultState);
  }

  private isLoading(ctx: StateContext<SessionStateModel>) {
    return ctx.getState().loading;
  }

  private readTokens(): JwtTokens | null {
    const rawTokens = JSON.parse(this.cookieService.get(GlobalService.TOKEN_KEY) || 'null');

    return !!rawTokens ? rawTokens : null;
  }

  private writeTokens(tokens: JwtTokens) {
    const cookieDomain = this.getCookieDomain();
    const expiryExists = this.cookieService.check(GlobalService.EXPIRY_KEY);
    const msIn390Days = 1000 * 3600 * 24 * 390;
    const expiry = expiryExists
      ? new Date(this.cookieService.get(GlobalService.EXPIRY_KEY))
      : new Date(Date.now() + msIn390Days);

    if (!expiryExists) {
      this.cookieService.set(
        GlobalService.EXPIRY_KEY,
        expiry.getTime().toString(),
        expiry,
        '/',
        cookieDomain,
        cookieDomain !== 'localhost',
        cookieDomain === 'localhost' ? 'Lax' : 'None'
      );
    }

    this.cookieService.set(
      GlobalService.TOKEN_KEY,
      JSON.stringify(tokens),
      expiry,
      '/',
      cookieDomain,
      cookieDomain !== 'localhost',
      cookieDomain === 'localhost' ? 'Lax' : 'None'
    );
  }

  private forgetTokens() {
    const cookieDomain = this.getCookieDomain();

    this.dataStorageService.remove(GlobalService.TOKEN_KEY);
    this.cookieService.set(
      GlobalService.TOKEN_KEY,
      '',
      new Date('Thu, 01 Jan 1970 00:00:01 GMT'),
      '/',
      cookieDomain,
      cookieDomain !== 'localhost',
      cookieDomain === 'localhost' ? 'Lax' : 'None'
    );

    this.cookieService.set(
      GlobalService.EXPIRY_KEY,
      '',
      new Date('Thu, 01 Jan 1970 00:00:01 GMT'),
      '/',
      cookieDomain,
      cookieDomain !== 'localhost',
      cookieDomain === 'localhost' ? 'Lax' : 'None'
    );
  }

  private getCookieDomain(): string {
    const cookieSubDomain = ['', ...this.document.location.hostname.split('.').slice(-2)].join('.');
    return cookieSubDomain === '.localhost' ? 'localhost' : cookieSubDomain;
  }
}
