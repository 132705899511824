import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

/**
 * Provide access to the router's previous URL, for instance, to distinguish initial from subsequent navigations.
 * https://stackoverflow.com/a/48866813/758806
 */
@Injectable({
  providedIn: 'root',
})
export class RouterExtensionService {
  previousUrl: string;

  private currentUrl: string;

  constructor(private readonly router: Router) {
    this.router.events.pipe(filter<NavigationEnd>(event => event instanceof NavigationEnd)).subscribe(event => {
      this.previousUrl = this.currentUrl;
      this.currentUrl = event.url;
    });
  }
}
