var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { OnDestroy, OnInit, } from '@angular/core';
import { ErrorHandlerService } from '@core/services/error-handler.service';
import { GlobalService } from '@core/services/global.service';
import { RouterExtensionService } from '@core/services/router-extension.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { Store } from '@ngxs/store';
import { SessionState } from '@store/session/session.state';
import { fromEvent, Subject } from 'rxjs';
import { filter, startWith, takeUntil } from 'rxjs/operators';
var MainComponent = /** @class */ (function () {
    // Pour démarrer la récolte des données analytics, il suffit simplement de s'injecter le service `Angulartics2GoogleAnalytics`.
    // NB: Dans la prochaine version, il faudra démarrer explicitement la récolte. Une méthode `start` sera mise à disposition
    function MainComponent(store, loader, document, platformId, localeId, errorHandlerService, _, // initialise globally to watch all router events,
    __ // initialise globally to initialize the tokens,
    ) {
        this.loader = loader;
        this.document = document;
        this.platformId = platformId;
        this.localeId = localeId;
        this.destroyed$ = new Subject();
        /**
         * Use class `hover-on` in CSS as follows:
         * `:host-context(.hover-on) .link:hover { ... }`
         */
        this.hoverEnabled = true;
        /**
         * Class `focus-off` disables all outlines automatically.
         */
        this.focusOutlineDisabled = false;
        if (isPlatformServer(platformId)) {
            return;
        }
        this.onMouseDown();
        this.onTouchStart();
        // Provide user data to Bugsnag
        store.select(SessionState.user).subscribe(function (user) {
            errorHandlerService.bugsnagClient.user = user && __assign({}, user, { email: user.username });
        });
    }
    Object.defineProperty(MainComponent.prototype, "hoverDisabled", {
        get: function () {
            return !this.hoverEnabled;
        },
        enumerable: true,
        configurable: true
    });
    MainComponent.prototype.ngOnInit = function () {
        if (isPlatformBrowser(this.platformId) && this.document.documentElement.lang === '') {
            this.setLanguageInDom(this.localeId);
        }
    };
    MainComponent.prototype.ngOnDestroy = function () {
        this.destroyed$.next();
        this.destroyed$.complete();
    };
    MainComponent.prototype.setLanguageInDom = function (language) {
        this.document.documentElement.lang = language;
    };
    /**
     * Enable hover if "mouse" pointer event is detected; disable it otherwise.
     * https://developer.mozilla.org/en-US/docs/Web/Events/pointerenter
     */
    MainComponent.prototype.onPointerEnter = function (event) {
        var evt = event; // https://github.com/kulshekhar/ts-jest/issues/1035
        this.hoverEnabled = evt.pointerType === 'mouse';
    };
    /**
     * Disable hover on `touchstart` to cover browsers that do not support pointer events.
     * https://caniuse.com/#feat=pointer
     */
    MainComponent.prototype.onTouchStart = function () {
        var _this = this;
        fromEvent(document, 'touchstart', { passive: true })
            .pipe(takeUntil(this.destroyed$), startWith(null), filter(function (event) { return event !== null; }))
            .subscribe(function () {
            _this.hoverEnabled = false;
        });
    };
    MainComponent.prototype.onMouseDown = function () {
        var _this = this;
        fromEvent(document, 'mousedown', { passive: true })
            .pipe(takeUntil(this.destroyed$), startWith(null), filter(function (event) { return event !== null; }))
            .subscribe(function () {
            _this.focusOutlineDisabled = true;
        });
    };
    MainComponent.prototype.onTabKeyDown = function () {
        this.focusOutlineDisabled = false;
    };
    return MainComponent;
}());
export { MainComponent };
