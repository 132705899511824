var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { isPlatformBrowser } from '@angular/common';
import { ErrorHandler } from '@angular/core';
import bugsnag from '@bugsnag/js';
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';
import { environment } from '@env/environment';
var REPORTED_ENVS = ['qa3', 'qa4', 'prod', 'staging', 'dev'];
var SILENCED_SUFFIX = ' (silenced)';
var MESSAGE_GROUPS = [
    { regex: /(Loading chunk) [0-9]+ (failed)[^]*/, msg: '$1 $2' },
    { regex: /(Uncaught \(in promise\): e: {)[^]*(\"status\":[0-9]{1,3},\"statusText\":\"[^"]+\")[^]*$/, msg: '$1$2}' },
    { regex: /(\/profiles)\/[^/]+(.*):/, msg: '$1/<profile-id>$2:' },
    { regex: /(\/user)\/[^/]+(.*):/, msg: '$1/<user-id>$2:' },
    { regex: /(\/company)\/[^/]+(.*):/, msg: '$1/<company-id>$2:' },
    { regex: /(\/jobs)\/[^/]+(.*):/, msg: '$1/<job-id>$2:' },
    { regex: /(\/schools)\/[^/]+(.*):/, msg: '$1/<school-id>$2:' },
    { regex: /(\/location\?name=)[^:]+/, msg: '$1<keyword>' },
    { regex: /(\/location)\/[^:]+/, msg: '$1/<location-id>' },
    { regex: /(\/wizbii-files)\/[^:]+/, msg: '$1/<file-name>' },
    { regex: /(\/m-api.wizbii.com\/v1)\/[^:]+/, msg: '$1/<media-endpoint>' },
    { regex: /(animation trigger ".+" has failed to build)[^]*/, msg: '$1' },
    { regex: /(JSONP request)[^]*(failed|timed out)$/, msg: '$1 $2' },
];
var ErrorHandlerService = /** @class */ (function (_super) {
    __extends(ErrorHandlerService, _super);
    function ErrorHandlerService(platformId) {
        return _super.call(this, isPlatformBrowser(platformId)
            ? bugsnag({
                apiKey: environment.bugsnagId,
                appVersion: environment.version,
                autoCaptureSessions: false,
                releaseStage: environment.name,
                beforeSend: function (report) {
                    if (!REPORTED_ENVS.includes(environment.name)) {
                        report.ignore();
                        return;
                    }
                    // Pass error message through all known message grouping rules
                    var groupedErrorMessage = MESSAGE_GROUPS.reduce(function (message, _a) {
                        var regex = _a.regex, msg = _a.msg;
                        return message.replace(regex, msg);
                    }, report.errorMessage);
                    // Group errors by class and message
                    report.groupingHash = report.errorClass + ": " + groupedErrorMessage;
                },
            })
            : // Can't initialise Bugsnag in SSR
                // https://github.com/bugsnag/bugsnag-js/issues/455
                null) || this;
    }
    /**
     * Silence incidental NGXS errors.
     * Workaround for handling errors outside of NGXS states as per ADR-001.
     * https://github.com/ngxs/store/issues/781
     */
    ErrorHandlerService.silence = function (error, condition) {
        if (condition) {
            error.message = "" + error.message + SILENCED_SUFFIX;
        }
    };
    /**
     * Override Angular's and Bugsnag's default error handling
     * in order to silence some errors and deal with SSR.
     */
    ErrorHandlerService.prototype.handleError = function (error) {
        if (error.message && error.message.endsWith(SILENCED_SUFFIX)) {
            // Log silenced error
            console.warn(error.message);
        }
        else if (this.bugsnagClient) {
            // In browser, handle error with Bugsnag
            _super.prototype.handleError.call(this, error);
        }
        else {
            // In SSR, skip Bugsnag and use Angular's error handler directly
            // https://github.com/bugsnag/bugsnag-js/issues/455
            ErrorHandler.prototype.handleError.call(this, error);
        }
    };
    /**
     * Notify Bugsnag of a known, handled error.
     */
    ErrorHandlerService.prototype.warn = function (message, error) {
        if (error === void 0) { error = {}; }
        console.warn(message);
        if (this.bugsnagClient) {
            this.bugsnagClient.notify(message, {
                severity: 'info',
                metaData: {
                    debug: error instanceof Error ? { type: error.name, message: error.message } : error,
                },
            });
        }
    };
    return ErrorHandlerService;
}(BugsnagErrorHandler));
export { ErrorHandlerService };
