import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { FeaturesRoutingEnum } from '@features/features-routing.enum';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class GlobalHttpInterceptorService implements HttpInterceptor {
  constructor(private readonly router: Router) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError(error => {
        if (error instanceof HttpErrorResponse) {
          if (!(error.error instanceof ErrorEvent)) {
            console.log(`error status : ${error.status} ${error.statusText}`);
            // Recruiter, job, candidate are the only services that can return a 403 depending on `refuse` recruiter status
            // That way we allow 403 to be catched by other potential services
            if (
              error.status === 403 &&
              (req.url.includes(environment.api.recruiter) ||
                req.url.includes(environment.api.job) ||
                req.url.includes(environment.api.candidate))
            ) {
              this.router.navigateByUrl(FeaturesRoutingEnum.NotAuthorized);
            }
          } else {
            console.error('Error Event');
          }
        }
        return throwError(error);
      })
    );
  }
}
