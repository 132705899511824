import { Inject, Injectable } from '@angular/core';
import { EventTrackerInterface, FacebookPixelData } from '@commons/tracker/event-tracker/event-tracker.interface';
import { WINDOW } from '@wizbii/angular-utilities';
import { trackEvent } from '@wizbii/tracking';

@Injectable()
export class EventTrackerService {
  constructor(@Inject(WINDOW) private readonly window: any) {}

  send(event: EventTrackerInterface): void {
    trackEvent(event.eventCategory, event.eventAction, event.eventLabel, event.eventValue as any);
  }

  sendRegisterToFacebookPixel() {
    if (!this.window.fbq) {
      return;
    }

    this.window.fbq('track', 'CompleteRegistration', { hitType: 'event' });
  }

  sendCreateJobToFacebookPixel(facebookPixelData: Partial<FacebookPixelData>) {
    if (!this.window.fbq) {
      return;
    }

    this.window.fbq('trackCustom', 'CreateJob', {
      category: 'Page Job',
      type: 'product',
      hitType: 'event',
      ...facebookPixelData,
    });
  }
}
