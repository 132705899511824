/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./popup.component";
var styles_PopupComponent = [];
var RenderType_PopupComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PopupComponent, data: {} });
export { RenderType_PopupComponent as RenderType_PopupComponent };
export function View_PopupComponent_0(_l) { return i0.ɵvid(2, [i0.ɵncd(null, 0)], null, null); }
export function View_PopupComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-popup", [], null, [["document", "click"], ["document", "keydown.escape"]], function (_v, en, $event) { var ad = true; if (("document:click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onDocumentClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("document:keydown.escape" === en)) {
        var pd_1 = (i0.ɵnov(_v, 1).onDocumentEscape() !== false);
        ad = (pd_1 && ad);
    } return ad; }, View_PopupComponent_0, RenderType_PopupComponent)), i0.ɵdid(1, 49152, null, 0, i1.PopupComponent, [i0.ElementRef], null, null)], null, null); }
var PopupComponentNgFactory = i0.ɵccf("app-popup", i1.PopupComponent, View_PopupComponent_Host_0, { opened: "opened", autoClose: "autoClose" }, { openedChange: "openedChange" }, ["*"]);
export { PopupComponentNgFactory as PopupComponentNgFactory };
