import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import {
  ApplicationWebservice,
  CANDIDATE_API_CONFIG,
  CompanyWebservice,
  RECRUITER_API_CONFIG,
  RecruiterWebservice,
} from '@ats/webservices';
import { TrackerModule } from '@commons/tracker/tracker.module';
import { HasAuthorizationsGuard } from '@core/guards/has-authorizations/has-authorizations.guard';
import { HasCandidateModalQueryParamGuard } from '@core/guards/has-candidate-modal-query-param/has-candidate-modal-query-param.guard';
import { InitGuard } from '@core/guards/init/init.guard';
import { IsAlreadyConnectedGuard } from '@core/guards/is-already-connected/is-already-connected.guard';
import { MustBeConnectedGuard } from '@core/guards/must-be-connected/must-be-connected.guard';
import { MustHaveRecruiterGuard } from '@core/guards/must-have-recruiter/must-have-recruiter.guard';
import { FileService } from '@core/services/file.service';
import { CityRequiredValidator } from '@domains/city/city-picker/city-required.validator';
import { environment } from '@env/environment';
import { FeaturesRoutingModule } from '@features/features-routing.module';
import { GlobalHttpInterceptorService } from '@features/global-http-interceptor.service';
import { NotFoundModule } from '@features/not-found/not-found.module';
import { AccountApiWebservice } from '@webservices/account-api/account-api.webservice';
import { CandidateApplicationWebservice } from '@webservices/candidate-api/candidate-application.webservice';
import { CandidateWebservice } from '@webservices/candidate-api/candidate.webservice';
import { CONSENT_API_CONFIG, ConsentWebservice } from '@webservices/consent-api/consent-api.webservice';
import { JobWebservice } from '@webservices/job-api/job.webservice';
import { HeadDescriptionModule, HeadTitleModule } from '@wizbii/seo-lib';
import {
  AUTHENTICATION_API_CONFIG,
  AuthenticationWebservice,
  CityWebservice,
  CONTACT_API_CONFIG,
  ContactWebservice,
  CONTENT_API_CONFIG as WIZBII_CONTENT_API_CONFIG,
  ContentLegalWebservice,
  FILE_API_CONFIG,
  FileWebservice,
  PLACE_API_CONFIG,
} from '@wizbii/webservices';

@NgModule({
  declarations: [CityRequiredValidator],
  imports: [CommonModule, FeaturesRoutingModule, NotFoundModule, HeadTitleModule, HeadDescriptionModule, TrackerModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: GlobalHttpInterceptorService,
      multi: true,
    },
    {
      provide: AUTHENTICATION_API_CONFIG,
      useValue: {
        appId: environment.applicationId,
        baseUrl: environment.api.authentication,
      },
    },
    AuthenticationWebservice,
    {
      provide: FILE_API_CONFIG,
      useValue: {
        baseUrl: environment.api.file,
        googleStorageUrl: environment.api.googleStorage,
        wizbiiFilesBucket: environment.wizbiiFiles,
      },
    },
    FileWebservice,
    FileService,
    {
      provide: CONTACT_API_CONFIG,
      useValue: {
        appId: environment.applicationId,
        baseUrl: environment.api.contact,
      },
    },
    ContactWebservice,
    {
      provide: PLACE_API_CONFIG,
      useValue: {
        baseUrl: environment.api.place,
      },
    },
    CityWebservice,
    {
      provide: RECRUITER_API_CONFIG,
      useValue: {
        baseUrl: environment.api.recruiter,
      },
    },
    RecruiterWebservice,
    CompanyWebservice,
    {
      provide: CANDIDATE_API_CONFIG,
      useValue: {
        baseUrl: environment.api.candidate,
      },
    },
    ApplicationWebservice,
    ContentLegalWebservice,
    {
      provide: WIZBII_CONTENT_API_CONFIG,
      useValue: {
        baseUrl: environment.api.content,
        locale: environment.locale,
      },
    },
    {
      provide: CONSENT_API_CONFIG,
      useValue: {
        appId: environment.applicationId,
        baseUrl: environment.api.consent,
      },
    },
    ConsentWebservice,

    InitGuard,
    MustBeConnectedGuard,
    IsAlreadyConnectedGuard,
    MustHaveRecruiterGuard,
    HasCandidateModalQueryParamGuard,
    HasAuthorizationsGuard,

    AccountApiWebservice,
    JobWebservice,
    CandidateWebservice,
    CandidateApplicationWebservice,
  ],
})
export class FeaturesModule {}
