/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./sticky-menu.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../header/profile-popup/profile-popup.component.ngfactory";
import * as i3 from "../header/profile-popup/profile-popup.component";
import * as i4 from "@ngxs/store";
import * as i5 from "@angular/router";
import * as i6 from "@angular/common";
import * as i7 from "../tracker/event-tracker/event-tracker.directive";
import * as i8 from "../tracker/event-tracker/event-tracker.service";
import * as i9 from "../svg-icon/svg-icon.component.ngfactory";
import * as i10 from "../svg-icon/svg-icon.component";
import * as i11 from "./sticky-menu.component";
var styles_StickyMenuComponent = [i0.styles];
var RenderType_StickyMenuComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StickyMenuComponent, data: {} });
export { RenderType_StickyMenuComponent as RenderType_StickyMenuComponent };
function View_StickyMenuComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-profile-popup", [], null, null, null, i2.View_ProfilePopupComponent_0, i2.RenderType_ProfilePopupComponent)), i1.ɵdid(1, 49152, null, 0, i3.ProfilePopupComponent, [i4.Store], { recruiter: [0, "recruiter"], company: [1, "company"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.recruiter; var currVal_1 = _co.company; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_StickyMenuComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "header", [["class", "header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "header__company company"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "a", [["appEventTracker", ""], ["class", "btn-icon btn-clean"], ["eventAction", "Click Logo"], ["eventCategory", "App Navigation"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (i1.ɵnov(_v, 5).doClick() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 671744, null, 0, i5.RouterLinkWithHref, [i5.Router, i5.ActivatedRoute, i6.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(4, 2), i1.ɵdid(5, 16384, null, 0, i7.EventTrackerDirective, [i8.EventTrackerService], { eventCategory: [0, "eventCategory"], eventAction: [1, "eventAction"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "svg-icon", [["height", "1.875em"], ["icon", "atsii"], ["label", "Atsii"], ["width", "5.0625em"]], null, null, null, i9.View_SvgIconComponent_0, i9.RenderType_SvgIconComponent)), i1.ɵdid(7, 49152, null, 0, i10.SvgIconComponent, [], { icon: [0, "icon"], label: [1, "label"], width: [2, "width"], height: [3, "height"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StickyMenuComponent_1)), i1.ɵdid(9, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _ck(_v, 4, 0, "/", _co.featuresRoutingEnum.Jobs); _ck(_v, 3, 0, currVal_2); var currVal_3 = "App Navigation"; var currVal_4 = "Click Logo"; _ck(_v, 5, 0, currVal_3, currVal_4); var currVal_5 = "atsii"; var currVal_6 = "Atsii"; var currVal_7 = "5.0625em"; var currVal_8 = "1.875em"; _ck(_v, 7, 0, currVal_5, currVal_6, currVal_7, currVal_8); var currVal_9 = (_co.recruiter && _co.company); _ck(_v, 9, 0, currVal_9); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).target; var currVal_1 = i1.ɵnov(_v, 3).href; _ck(_v, 2, 0, currVal_0, currVal_1); }); }
export function View_StickyMenuComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-sticky-menu", [], null, null, null, View_StickyMenuComponent_0, RenderType_StickyMenuComponent)), i1.ɵdid(1, 49152, null, 0, i11.StickyMenuComponent, [], null, null)], null, null); }
var StickyMenuComponentNgFactory = i1.ɵccf("app-sticky-menu", i11.StickyMenuComponent, View_StickyMenuComponent_Host_0, { recruiter: "recruiter", company: "company" }, {}, []);
export { StickyMenuComponentNgFactory as StickyMenuComponentNgFactory };
