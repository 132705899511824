import { DOCUMENT, isPlatformBrowser, isPlatformServer } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ErrorHandler,
  HostBinding,
  HostListener,
  Inject,
  LOCALE_ID,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { ErrorHandlerService } from '@core/services/error-handler.service';
import { GlobalService } from '@core/services/global.service';
import { RouterExtensionService } from '@core/services/router-extension.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { Store } from '@ngxs/store';
import { SessionState } from '@store/session/session.state';
import { fromEvent, Subject } from 'rxjs';
import { filter, startWith, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainComponent implements OnInit, OnDestroy {
  private readonly destroyed$ = new Subject<void>();

  /**
   * Use class `hover-on` in CSS as follows:
   * `:host-context(.hover-on) .link:hover { ... }`
   */
  @HostBinding('class.hover-on') hoverEnabled = true;

  @HostBinding('class.hover-off')
  get hoverDisabled() {
    return !this.hoverEnabled;
  }

  /**
   * Class `focus-off` disables all outlines automatically.
   */
  @HostBinding('class.focus-off') focusOutlineDisabled = false;

  // Pour démarrer la récolte des données analytics, il suffit simplement de s'injecter le service `Angulartics2GoogleAnalytics`.
  // NB: Dans la prochaine version, il faudra démarrer explicitement la récolte. Une méthode `start` sera mise à disposition
  constructor(
    store: Store,
    public loader: LoadingBarService,
    @Inject(DOCUMENT) private readonly document: Document,
    @Inject(PLATFORM_ID) private readonly platformId: Object,
    @Inject(LOCALE_ID) private readonly localeId: string,
    @Inject(ErrorHandler) errorHandlerService: ErrorHandlerService,
    _: RouterExtensionService, // initialise globally to watch all router events,
    __: GlobalService // initialise globally to initialize the tokens,
  ) {
    if (isPlatformServer(platformId)) {
      return;
    }

    this.onMouseDown();
    this.onTouchStart();

    // Provide user data to Bugsnag
    store.select(SessionState.user).subscribe(user => {
      errorHandlerService.bugsnagClient.user = user && { ...user, email: user.username };
    });
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId) && this.document.documentElement.lang === '') {
      this.setLanguageInDom(this.localeId);
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private setLanguageInDom(language: string): void {
    this.document.documentElement.lang = language;
  }

  /**
   * Enable hover if "mouse" pointer event is detected; disable it otherwise.
   * https://developer.mozilla.org/en-US/docs/Web/Events/pointerenter
   */
  @HostListener('pointerenter', ['$event'])
  onPointerEnter(event) {
    const evt: PointerEvent = event; // https://github.com/kulshekhar/ts-jest/issues/1035
    this.hoverEnabled = evt.pointerType === 'mouse';
  }

  /**
   * Disable hover on `touchstart` to cover browsers that do not support pointer events.
   * https://caniuse.com/#feat=pointer
   */
  onTouchStart() {
    fromEvent(document, 'touchstart', { passive: true })
      .pipe(
        takeUntil(this.destroyed$),
        startWith(null),
        filter(event => event !== null)
      )
      .subscribe(() => {
        this.hoverEnabled = false;
      });
  }

  onMouseDown() {
    fromEvent(document, 'mousedown', { passive: true })
      .pipe(
        takeUntil(this.destroyed$),
        startWith(null),
        filter(event => event !== null)
      )
      .subscribe(() => {
        this.focusOutlineDisabled = true;
      });
  }

  @HostListener('keydown.Tab')
  onTabKeyDown() {
    this.focusOutlineDisabled = false;
  }
}
