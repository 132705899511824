import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { environment } from '@env/environment';
import { AccountResourceInterface } from '@models/account/account.resource';
import { RecruiterAccountResourceInterface } from '@models/account/recruiter-account.resource';
import { LocaleEnum } from '@models/commons/locales.enum';
import { CompanyResourceInterface } from '@models/company/company.resource';
import { RecruiterResourceInterface } from '@models/recruiter/recruiter.resource';
import { JwtTokens } from '@wizbii/angular-utilities';
import { Observable } from 'rxjs';

@Injectable()
export class AccountApiWebservice {
  private readonly baseUrl = `${environment.api.account}/v1/account`;

  constructor(private readonly http: HttpClient, @Inject(LOCALE_ID) private readonly localeId: string) {}

  create(
    recruiter: RecruiterResourceInterface,
    company: CompanyResourceInterface,
    account?: AccountResourceInterface,
    invitationToken?: string
  ): Observable<JwtTokens> {
    const payload: RecruiterAccountResourceInterface = {
      recruiter,
      company,
      ...(account
        ? {
            account: {
              ...account,
              appId: environment.applicationId,
              locale: LocaleEnum[this.localeId],
            },
          }
        : {}),
      ...(invitationToken ? { invitationToken } : null),
    };

    return this.http.post<JwtTokens>(`${this.baseUrl}/recruiters`, payload, {
      withCredentials: true,
      headers: { ignoreLoadingBar: '' },
    });
  }
}
