import { HttpErrorResponse } from '@angular/common/http';
import { Recruiter } from '@ats/models';
import { RoleStatusEnum } from '@ats/models/lib/recruiter-api/recruiter/role-status.enum';

export class SetRecruiter {
  static readonly type = '[Recruiter] Set';
  constructor(public recruiter: Recruiter) {}
}

export class GetRecruiterByUserId {
  static readonly type = '[Recruiter] Get by user Id';
  constructor(readonly id: string) {}
}

export class FindRecruitersByCompanyId {
  static readonly type = '[Recruiter] Find by company Id';
}

export class GetRecruiterById {
  static readonly type = '[Recruiter] Get by Id';
  constructor(readonly id: string) {}
}

export class GetRecruiterSuccess {
  static readonly type = '[Recruiter] Get by user id success';
  constructor(readonly recruiter: Recruiter) {}
}

export class SetAuthenticatedRecruiter {
  static readonly type = '[Recruiter] Set authenticated recruiterId';
  constructor(readonly id: string) {}
}

export class AddAuthorizations {
  static readonly type = '[Recruiter] Add authorizations';
  constructor(readonly recruiter: Recruiter, readonly authorizations: RoleStatusEnum[]) {}
}

export class DeleteAuthorizations {
  static readonly type = '[Recruiter] Delete authorizations';
  constructor(readonly recruiter: Recruiter, readonly authorizations: RoleStatusEnum[]) {}
}

export class HandleErrors {
  static readonly type = '[Recruiter] Handle Errors';
  constructor(public error: HttpErrorResponse) {}
}
