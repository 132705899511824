import { Recruiter, toRecruiter } from '@ats/models';
import { RecruiterWebservice } from '@ats/webservices';
import { NotifStatus } from '@commons/notifications/notif-status.enum';
import { NotificationsService } from '@commons/notifications/notifications.service';
import { FeaturesRoutingEnum } from '@features/features-routing.enum';
import { Navigate } from '@ngxs/router-plugin';
import { Action, createSelector, Selector, State, StateContext, Store } from '@ngxs/store';
import { patch, updateItem } from '@ngxs/store/operators';
import { SetAuthenticatedCompany } from '@store/companies/companies.actions';
import {
  AddAuthorizations,
  DeleteAuthorizations,
  FindRecruitersByCompanyId,
  GetRecruiterById,
  GetRecruiterByUserId,
  GetRecruiterSuccess,
  HandleErrors,
  SetAuthenticatedRecruiter,
  SetRecruiter,
} from '@store/recruiters/recruiters.actions';
import { SessionState } from '@store/session/session.state';
import { of } from 'rxjs';
import { catchError, filter, first, map, switchMap } from 'rxjs/operators';

export class RecruitersStateModel {
  authenticatedRecruiterId: string;
  recruiters: Record<string, Recruiter>;
  recruiterList: Recruiter[];

  error: any;
}

const defaultState: RecruitersStateModel = {
  authenticatedRecruiterId: null,
  recruiters: {},
  recruiterList: null,

  error: null,
};

export const recruiterRefusedText = 'Recruiter refused';

@State<RecruitersStateModel>({
  name: 'recruiters',
  defaults: defaultState,
})
export class RecruitersState {
  static recruiterById(recruiterId: string) {
    return createSelector(
      [RecruitersState],
      (state: RecruitersStateModel) => {
        return state.recruiters[recruiterId];
      }
    );
  }

  static recruiterByUserId(userId: string) {
    return createSelector(
      [RecruitersState],
      (state: RecruitersStateModel) => {
        return Object.values(state.recruiters).find(recruiter => recruiter.userId === userId);
      }
    );
  }

  @Selector()
  static authenticatedRecruiter(state: RecruitersStateModel) {
    return state.recruiters[state.authenticatedRecruiterId];
  }

  @Selector()
  static authenticatedRecruiterId(state: RecruitersStateModel) {
    return state.authenticatedRecruiterId;
  }

  @Selector()
  static list(state: RecruitersStateModel) {
    return state.recruiterList;
  }

  @Selector()
  static listLength(state: RecruitersStateModel) {
    return (state.recruiterList || []).length;
  }

  constructor(
    private readonly recruiterWebservice: RecruiterWebservice,
    private readonly store: Store,
    private readonly notifService: NotificationsService
  ) {}

  @Action(GetRecruiterByUserId)
  getRecruiterByUserId(ctx: StateContext<RecruitersStateModel>, { id }: GetRecruiterByUserId) {
    const { recruiters } = ctx.getState();

    if (!recruiters[id]) {
      return this.recruiterWebservice.getByUserId(id).pipe(
        switchMap(profile => ctx.dispatch(new GetRecruiterSuccess(profile))),
        catchError(error => ctx.dispatch(new HandleErrors(error)))
      );
    }
  }

  @Action(GetRecruiterById)
  getRecruiterById(ctx: StateContext<RecruitersStateModel>, { id }: GetRecruiterById) {
    const { recruiters } = ctx.getState();

    if (!recruiters[id]) {
      return this.recruiterWebservice.getRecruiterById(id).pipe(
        switchMap(profile => ctx.dispatch(new GetRecruiterSuccess(profile))),
        catchError(error => ctx.dispatch(new HandleErrors(error)))
      );
    }
  }

  @Action(FindRecruitersByCompanyId)
  findRecruitersByCompanyId(ctx: StateContext<RecruitersStateModel>) {
    const { recruiters, authenticatedRecruiterId } = ctx.getState();

    return this.recruiterWebservice.findRecruitersByCompanyId(recruiters[authenticatedRecruiterId].companyId).pipe(
      map(recruiterList => {
        ctx.patchState({
          recruiterList,
        });
        return recruiterList;
      }),
      catchError(error => {
        this.notifService.createToast(
          'Une erreur est survenue lors de la récupération des collaborateurs.',
          NotifStatus.Failed
        );
        return ctx.dispatch(new HandleErrors(error));
      })
    );
  }

  @Action(AddAuthorizations)
  addAuthorizations(ctx: StateContext<RecruitersStateModel>, { recruiter, authorizations }: AddAuthorizations) {
    return ctx.setState(
      patch<RecruitersStateModel>({
        recruiterList: updateItem<Recruiter>(
          rec => rec._id === recruiter._id,
          toRecruiter({
            ...recruiter,
            authorizations: [...recruiter.authorizations, ...authorizations],
          })
        ),
      })
    );
  }

  @Action(DeleteAuthorizations)
  deleteAuthorizations(ctx: StateContext<RecruitersStateModel>, { recruiter, authorizations }: DeleteAuthorizations) {
    return ctx.setState(
      patch<RecruitersStateModel>({
        recruiterList: updateItem<Recruiter>(
          rec => rec._id === recruiter._id,
          toRecruiter({
            ...recruiter,
            authorizations: recruiter.authorizations.filter(authorization => !authorizations.includes(authorization)),
          })
        ),
      })
    );
  }

  @Action(GetRecruiterSuccess)
  getRecruiterSuccess(ctx: StateContext<RecruitersStateModel>, { recruiter }: GetRecruiterSuccess) {
    return ctx.setState(
      patch<RecruitersStateModel>({
        authenticatedRecruiterId: recruiter._id,
        recruiters: patch({
          [recruiter._id]: recruiter,
        }),
      })
    );
  }

  @Action(SetAuthenticatedRecruiter)
  setAuthenticatedRecruiter(ctx: StateContext<RecruitersStateModel>, { id }: SetAuthenticatedRecruiter) {
    return ctx.dispatch(new GetRecruiterByUserId(id)).pipe(
      switchMap(() => this.store.select(RecruitersState.authenticatedRecruiter)),
      filter(recruiter => !!recruiter),
      first(),
      switchMap(recruiter => ctx.dispatch(new SetAuthenticatedCompany(recruiter.companyId)))
    );
  }

  @Action(SetRecruiter)
  setApplication(ctx: StateContext<RecruitersStateModel>, { recruiter }: SetRecruiter) {
    return ctx.setState(
      patch<RecruitersStateModel>({
        recruiters: patch<Record<string, Recruiter>>({
          [recruiter._id]: recruiter,
        }),
      })
    );
  }

  @Action(HandleErrors)
  handleErrors(ctx: StateContext<RecruitersStateModel>, { error }: HandleErrors) {
    // tslint:disable no-small-switch
    switch (error.status) {
      // The Job doesn't exist so => 404
      case 404: {
        console.error('Not found');

        if (this.store.selectSnapshot(SessionState.hasTokens)) {
          return of();
        }

        ctx.patchState({ error });
        return ctx.dispatch(new Navigate(['/', FeaturesRoutingEnum.NotFound], undefined, { skipLocationChange: true }));
      }

      default: {
        console.error(`Code ${error.status} => ${error.statusText}`);
        return ctx.patchState({ error });
      }
    }
    // tslint:enable no-small-switch
  }
}
