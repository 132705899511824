var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { trackEvent } from '@wizbii/tracking';
var EventTrackerService = /** @class */ (function () {
    function EventTrackerService(window) {
        this.window = window;
    }
    EventTrackerService.prototype.send = function (event) {
        trackEvent(event.eventCategory, event.eventAction, event.eventLabel, event.eventValue);
    };
    EventTrackerService.prototype.sendRegisterToFacebookPixel = function () {
        if (!this.window.fbq) {
            return;
        }
        this.window.fbq('track', 'CompleteRegistration', { hitType: 'event' });
    };
    EventTrackerService.prototype.sendCreateJobToFacebookPixel = function (facebookPixelData) {
        if (!this.window.fbq) {
            return;
        }
        this.window.fbq('trackCustom', 'CreateJob', __assign({ category: 'Page Job', type: 'product', hitType: 'event' }, facebookPixelData));
    };
    return EventTrackerService;
}());
export { EventTrackerService };
