/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./svg-icon.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./svg-icon.component";
var styles_SvgIconComponent = [i0.styles];
var RenderType_SvgIconComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SvgIconComponent, data: {} });
export { RenderType_SvgIconComponent as RenderType_SvgIconComponent };
function View_SvgIconComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, ":svg:svg", [["class", "svg-icon"], ["focusable", "false"]], [[1, "width", 0], [1, "height", 0], [1, "viewBox", 0], [1, "preserveAspectRatio", 0], [1, "role", 0], [1, "aria-label", 0]], null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 0, ":svg:use", [], [[1, ":xlink:href", 0]], null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_6 = "svg-icon"; var currVal_7 = _co.classes; _ck(_v, 2, 0, currVal_6, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.width; var currVal_1 = (_co.height || _co.width); var currVal_2 = _co.viewBox; var currVal_3 = _co.preserveAspectRatio; var currVal_4 = (_co.label ? "img" : "presentation"); var currVal_5 = _co.label; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_8 = ("#icon-" + _co.icon); _ck(_v, 3, 0, currVal_8); }); }
export function View_SvgIconComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_SvgIconComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.width; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_SvgIconComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "svg-icon", [], null, null, null, View_SvgIconComponent_0, RenderType_SvgIconComponent)), i1.ɵdid(1, 49152, null, 0, i3.SvgIconComponent, [], null, null)], null, null); }
var SvgIconComponentNgFactory = i1.ɵccf("svg-icon", i3.SvgIconComponent, View_SvgIconComponent_Host_0, { icon: "icon", label: "label", classes: "classes", width: "width", height: "height", viewBox: "viewBox", preserveAspectRatio: "preserveAspectRatio" }, {}, []);
export { SvgIconComponentNgFactory as SvgIconComponentNgFactory };
