import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PopupItemDirective } from '@commons/popup/popup-item.directive';
import { PopupTriggerDirective } from '@commons/popup/popup-trigger.directive';
import { PopupComponent } from '@commons/popup/popup/popup.component';

@NgModule({
  imports: [CommonModule],
  declarations: [PopupComponent, PopupTriggerDirective, PopupItemDirective],
  exports: [PopupComponent, PopupTriggerDirective, PopupItemDirective],
})
export class PopupModule {}
