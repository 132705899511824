<ng-container *ngIf="loader.progress$ | async as progress">
  <mat-progress-bar
    *ngIf="progress > 0"
    class="mat-progress-bar--only-progress mat-progress-bar--fixed-top"
    mode="determinate"
    [value]="progress"
  ></mat-progress-bar>
</ng-container>

<router-outlet></router-outlet>

<app-sprite></app-sprite>

<app-notifications></app-notifications>
