import { ErrorHandler, Inject, Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { ErrorHandlerService } from '@core/services/error-handler.service';
import { FeaturesRoutingEnum } from '@features/features-routing.enum';
import { Store } from '@ngxs/store';
import { SessionState } from '@store/session/session.state';
import { buildPath } from '@wizbii/angular-utilities';
import { Observable } from 'rxjs';
import { filter, first, map, switchMap } from 'rxjs/operators';

@Injectable()
export class MustBeConnectedGuard implements CanActivate {
  constructor(
    private readonly router: Router,
    private readonly store: Store,
    @Inject(ErrorHandler) private readonly errorHandlerService: ErrorHandlerService
  ) {}

  canActivate(): Observable<boolean> {
    return this.store.select(SessionState.isInitialized).pipe(
      filter(isInitialized => isInitialized),
      first(),
      switchMap(() => this.store.selectOnce(SessionState.tokens)),
      map(tokens => !!tokens),
      map(isLogged => {
        if (!isLogged) {
          this.errorHandlerService.warn('Unauthorized route');

          this.router.navigate([buildPath(FeaturesRoutingEnum.NotFound)], { skipLocationChange: true });
        }

        return isLogged;
      })
    );
  }
}
