import { isPlatformBrowser } from '@angular/common';
import { NgZone } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { trackingConfig } from '@config/tracking-config';
import { environment } from '@env/environment';
import { Store } from '@ngxs/store';
import { SetConsents } from '@store/consents/consents.actions';
import { ImageService, } from '@wizbii/angular-utilities';
import { setupTracking, track } from '@wizbii/tracking';
import { from } from 'rxjs';
import { filter, map, startWith, switchMap, tap } from 'rxjs/operators';
var ɵ0 = new ImageService(environment.api.imaginary);
var CoreModule = /** @class */ (function () {
    function CoreModule(router, ngZone, store, platformId, window) {
        var _this = this;
        ngZone.runOutsideAngular(function () {
            if (isPlatformBrowser(platformId)) {
                _this.initializeTracking(router, window, store);
            }
        });
    }
    CoreModule.prototype.initializeTracking = function (router, window, store) {
        setupTracking(trackingConfig).then(function () {
            from(window.WizbiiGdpr.getConsents())
                .pipe(tap(function (consents) { return store.dispatch(new SetConsents(consents)); }), switchMap(function () { return router.events; }), filter(function (event) { return event instanceof NavigationEnd; }), map(function (event) { return event.urlAfterRedirects; }), startWith(window.location.href.replace(window.origin, '')), map(function (page) { return ({ url: window.location.href, page: page }); }))
                .subscribe(function (event) {
                track('pageview', event);
            });
        });
    };
    return CoreModule;
}());
export { CoreModule };
export { ɵ0 };
