import { Company } from '@ats/models';
import { CompanyWebservice } from '@ats/webservices';
import { atsDomain } from '@env/environment';
import { FeaturesRoutingEnum } from '@features/features-routing.enum';
import { Navigate } from '@ngxs/router-plugin';
import { Action, createSelector, Selector, State, StateContext } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import {
  GetCompany,
  GetCompanySuccess,
  HandleErrors,
  SetAuthenticatedCompany,
  UpdateCompany,
} from '@store/companies/companies.actions';
import { catchError, switchMap } from 'rxjs/operators';

export class CompaniesStateModel {
  authenticatedCompanyId: string;
  companies: Record<string, Company>;

  error: any;
}

const defaultState: CompaniesStateModel = {
  authenticatedCompanyId: null,
  companies: {},

  error: null,
};

export const companyRefusedText = 'Company refused';

@State<CompaniesStateModel>({
  name: 'companies',
  defaults: defaultState,
})
export class CompaniesState {
  static company(companyId: string) {
    return createSelector(
      [CompaniesState],
      (state: CompaniesStateModel) => {
        return state.companies[companyId];
      }
    );
  }

  @Selector()
  static authenticatedCompany(state: CompaniesStateModel) {
    return state.companies[state.authenticatedCompanyId];
  }

  @Selector()
  static authenticatedCompanyId(state: CompaniesStateModel) {
    return state.authenticatedCompanyId;
  }

  @Selector()
  static authenticatedCompanyCareerSite(state: CompaniesStateModel) {
    return `https://${state.authenticatedCompanyId}.${atsDomain}`;
  }

  constructor(private readonly companyWebservice: CompanyWebservice) {}

  @Action(GetCompany)
  getCompany(ctx: StateContext<CompaniesStateModel>, { id }: GetCompany) {
    const { companies } = ctx.getState();

    if (!companies[id]) {
      return this.companyWebservice.get(id).pipe(
        switchMap(company => ctx.dispatch(new GetCompanySuccess(company))),
        catchError(error => ctx.dispatch(new HandleErrors(error)))
      );
    }
  }

  @Action(GetCompanySuccess)
  getCompanySuccess(ctx: StateContext<CompaniesStateModel>, { company }: GetCompanySuccess) {
    return ctx.setState(
      patch<CompaniesStateModel>({
        companies: patch({
          [company._id]: company,
        }),
      })
    );
  }

  @Action(SetAuthenticatedCompany)
  setAuthenticatedCompany(ctx: StateContext<CompaniesStateModel>, { id }: SetAuthenticatedCompany) {
    ctx.patchState({ authenticatedCompanyId: id });
    return ctx.dispatch(new GetCompany(id));
  }

  @Action(UpdateCompany)
  updateCompany(ctx: StateContext<CompaniesStateModel>, { company }: UpdateCompany) {
    return ctx.setState(
      patch<CompaniesStateModel>({
        companies: patch({ [company._id]: company }),
      })
    );
  }

  @Action(HandleErrors)
  handleErrors(ctx: StateContext<CompaniesStateModel>, { error }: HandleErrors) {
    // tslint:disable no-small-switch
    switch (error.status) {
      // The Company doesn't exist so => 404
      case 404: {
        if (error.error === companyRefusedText) {
          console.error(companyRefusedText);

          ctx.patchState({ error });
          return ctx.dispatch(
            new Navigate(['/', FeaturesRoutingEnum.NotAuthorized], undefined, { skipLocationChange: true })
          );
        }

        console.error('Not found');

        ctx.patchState({ error });
        return ctx.dispatch(new Navigate(['/', FeaturesRoutingEnum.NotFound], undefined, { skipLocationChange: true }));
      }

      default: {
        console.error(`Code ${error.status} => ${error.statusText}`);
        return ctx.patchState({ error });
      }
    }
    // tslint:enable no-small-switch
  }
}
