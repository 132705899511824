import { HttpErrorResponse } from '@angular/common/http';
import { Company } from '@ats/models';

export class GetCompany {
  static readonly type = '[Company] Get';
  constructor(readonly id: string) {}
}

export class GetCompanySuccess {
  static readonly type = '[Company] Get Success';
  constructor(readonly company: Company) {}
}

export class SetAuthenticatedCompany {
  static readonly type = '[Company] Set authenticated companyId';
  constructor(readonly id: string) {}
}

export class UpdateCompany {
  static readonly type = '[Company] Update company';
  constructor(readonly company: Company) {}
}

export class HandleErrors {
  static readonly type = '[Company] Handle Errors';
  constructor(public error: HttpErrorResponse) {}
}
