var _a;
export var LangEnum;
(function (LangEnum) {
    LangEnum["fr"] = "fr";
})(LangEnum || (LangEnum = {}));
export var LocaleEnum;
(function (LocaleEnum) {
    LocaleEnum["fr"] = "fr_FR";
})(LocaleEnum || (LocaleEnum = {}));
export var Locales = (_a = {},
    _a[LangEnum.fr] = LocaleEnum.fr,
    _a);
