import { Routes } from '@angular/router';
import { RoleStatusEnum } from '@ats/models';
import { HasAuthorizationsGuard } from '@core/guards/has-authorizations/has-authorizations.guard';
import { InitGuard } from '@core/guards/init/init.guard';
import { IsAlreadyConnectedGuard } from '@core/guards/is-already-connected/is-already-connected.guard';
import { MustBeConnectedGuard } from '@core/guards/must-be-connected/must-be-connected.guard';
import { MustHaveRecruiterGuard } from '@core/guards/must-have-recruiter/must-have-recruiter.guard';
import { FeaturesRoutingEnum } from '@features/features-routing.enum';
import { NotFoundComponent } from '@features/not-found/not-found.component';
var ɵ0 = function () { return import("./wizbii/wizbii.module.ngfactory").then(function (m) { return m.WizbiiModuleNgFactory; }); }, ɵ1 = function () { return import("./sign-in/sign-in.module.ngfactory").then(function (m) { return m.SignInModuleNgFactory; }); }, ɵ2 = function () { return import("./sign-up/sign-up.module.ngfactory").then(function (m) { return m.SignUpModuleNgFactory; }); }, ɵ3 = function () { return import("./lost-password/lost-password.module.ngfactory").then(function (m) { return m.LostPasswordModuleNgFactory; }); }, ɵ4 = { authorizations: [RoleStatusEnum.recruitment] }, ɵ5 = function () { return import("./jobs/jobs.module.ngfactory").then(function (m) { return m.JobsModuleNgFactory; }); }, ɵ6 = { authorizations: [RoleStatusEnum.career] }, ɵ7 = function () { return import("./company/company.module.ngfactory").then(function (m) { return m.CompanyModuleNgFactory; }); }, ɵ8 = function () { return import("./profile/profile.module.ngfactory").then(function (m) { return m.ProfileModuleNgFactory; }); }, ɵ9 = function () { return import("./parameters/parameters.module.ngfactory").then(function (m) { return m.ParametersModuleNgFactory; }); }, ɵ10 = function () { return import("./confidentiality/confidentiality.module.ngfactory").then(function (m) { return m.ConfidentialityModuleNgFactory; }); }, ɵ11 = { authorizations: [RoleStatusEnum.admin] }, ɵ12 = function () { return import("./contributors/contributors.module.ngfactory").then(function (m) { return m.ContributorsModuleNgFactory; }); }, ɵ13 = function () { return import("./not-authorized/not-authorized.module.ngfactory").then(function (m) { return m.NotAuthorizedModuleNgFactory; }); }, ɵ14 = { unhandled404: true };
var routes = [
    {
        path: FeaturesRoutingEnum.Root,
        pathMatch: 'full',
        redirectTo: "/" + FeaturesRoutingEnum.SignIn,
    },
    {
        path: FeaturesRoutingEnum.Wizbii,
        canActivate: [InitGuard],
        loadChildren: ɵ0,
    },
    {
        path: FeaturesRoutingEnum.SignIn,
        canActivate: [InitGuard, IsAlreadyConnectedGuard],
        loadChildren: ɵ1,
    },
    {
        path: FeaturesRoutingEnum.SignUp,
        canActivate: [InitGuard, IsAlreadyConnectedGuard],
        loadChildren: ɵ2,
    },
    {
        path: FeaturesRoutingEnum.LostPassword,
        canActivate: [InitGuard],
        loadChildren: ɵ3,
    },
    {
        path: FeaturesRoutingEnum.Jobs,
        canActivate: [InitGuard, MustBeConnectedGuard, MustHaveRecruiterGuard, HasAuthorizationsGuard],
        data: ɵ4,
        loadChildren: ɵ5,
    },
    {
        path: FeaturesRoutingEnum.Company,
        canActivate: [InitGuard, MustBeConnectedGuard, MustHaveRecruiterGuard, HasAuthorizationsGuard],
        data: ɵ6,
        loadChildren: ɵ7,
    },
    {
        path: FeaturesRoutingEnum.Profile,
        canActivate: [InitGuard, MustBeConnectedGuard, MustHaveRecruiterGuard],
        loadChildren: ɵ8,
    },
    {
        path: FeaturesRoutingEnum.Parameters,
        canActivate: [InitGuard, MustBeConnectedGuard, MustHaveRecruiterGuard],
        loadChildren: ɵ9,
    },
    {
        path: FeaturesRoutingEnum.Confidentiality,
        canActivate: [InitGuard, MustBeConnectedGuard, MustHaveRecruiterGuard],
        loadChildren: ɵ10,
    },
    {
        path: FeaturesRoutingEnum.Contributors,
        canActivate: [InitGuard, MustBeConnectedGuard, HasAuthorizationsGuard],
        data: ɵ11,
        loadChildren: ɵ12,
    },
    {
        path: FeaturesRoutingEnum.NotAuthorized,
        loadChildren: ɵ13,
    },
    {
        path: FeaturesRoutingEnum.NotFound,
        component: NotFoundComponent,
        canActivate: [InitGuard],
    },
    {
        path: '**',
        component: NotFoundComponent,
        canActivate: [InitGuard],
        data: ɵ14,
    },
];
var FeaturesRoutingModule = /** @class */ (function () {
    function FeaturesRoutingModule() {
    }
    return FeaturesRoutingModule;
}());
export { FeaturesRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14 };
