import { HttpErrorResponse } from '@angular/common/http';
import { Application, Utm } from '@ats/models';
import { ApplicationWebservice } from '@ats/webservices';
import { NotifStatus } from '@commons/notifications/notif-status.enum';
import { NotificationsService } from '@commons/notifications/notifications.service';
import { FeaturesRoutingEnum } from '@features/features-routing.enum';
import { Navigate } from '@ngxs/router-plugin';
import { Action, createSelector, Selector, State, StateContext } from '@ngxs/store';
import { iif, insertItem, patch, updateItem } from '@ngxs/store/operators';
import {
  FindApplications,
  HandleErrors,
  InitStaticData,
  SetApplication,
} from '@store/applications/applications.actions';
import { buildUrl } from '@wizbii/angular-utilities';
import { of, throwError, zip } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

export class ApplicationStateModel {
  applications: Record<string, Application[]>;
  status: string[];
  sourcesPlatforms: { name: string; utm: Utm }[];
  error: any;
}

const defaultState: ApplicationStateModel = {
  applications: {},
  status: [],
  sourcesPlatforms: [],

  error: null,
};

@State<ApplicationStateModel>({
  name: 'applications',
  defaults: defaultState,
})
export class ApplicationsState {
  @Selector()
  static status(state: ApplicationStateModel) {
    return state.status;
  }

  @Selector()
  static sourcesPlatforms(state: ApplicationStateModel) {
    return state.sourcesPlatforms;
  }

  static sourcePlatform(name: string) {
    return createSelector(
      [ApplicationsState],
      (state: ApplicationStateModel) =>
        state.sourcesPlatforms.find(source => name.toLowerCase().includes(source.utm.source.toLowerCase()))
    );
  }

  static application(jobId: string, applicationId: string) {
    return createSelector(
      [ApplicationsState],
      (state: ApplicationStateModel) => state.applications[jobId].find(application => application.id === applicationId)
    );
  }

  constructor(
    private readonly applicationWebservice: ApplicationWebservice,
    private readonly notifService: NotificationsService
  ) {}

  @Action(InitStaticData)
  initStaticData(ctx: StateContext<ApplicationStateModel>) {
    const { status, sourcesPlatforms } = ctx.getState();

    if (status.length > 0 && sourcesPlatforms.length > 0) {
      return undefined;
    }

    return zip(
      this.applicationWebservice.getStatus({
        headers: { ignoreLoadingBar: '' },
      }),
      this.applicationWebservice.getSourcesPlatform({
        headers: { ignoreLoadingBar: '' },
      })
    ).pipe(
      switchMap(([_status, _sourcesPlatforms]) =>
        of(
          ctx.patchState({
            status: _status,
            sourcesPlatforms: _sourcesPlatforms,
          })
        )
      ),
      catchError((err: HttpErrorResponse | Error) => {
        if (err instanceof HttpErrorResponse && err.status === 500) {
          return throwError(new Error('Internal Server Error while getting datas'));
        }

        return throwError(err);
      })
    );
  }

  @Action(SetApplication)
  setApplication(ctx: StateContext<ApplicationStateModel>, { application }: SetApplication) {
    return ctx.setState(
      patch<ApplicationStateModel>({
        applications: patch<Record<string, Application[]>>({
          [application.jobId]: iif<Application[]>(
            applications => !!applications.find(app => app.id === application.id),
            updateItem(app => app.id === application.id, application),
            insertItem(application)
          ),
        }),
      })
    );
  }

  @Action(FindApplications)
  getApplications(ctx: StateContext<ApplicationStateModel>, { jobId }: FindApplications) {
    return this.applicationWebservice.findApplications(jobId).pipe(
      switchMap(applications => {
        ctx.setState(
          patch<ApplicationStateModel>({
            applications: {
              [jobId]: applications,
            },
          })
        );
        return of(applications);
      }),
      catchError(error => ctx.dispatch(new HandleErrors(error)))
    );
  }

  @Action(HandleErrors)
  handleErrors(ctx: StateContext<ApplicationStateModel>, { error }: HandleErrors) {
    // tslint:disable no-small-switch
    switch (error.status) {
      // The Job doesn't exist so => 404
      case 404: {
        console.error('Not found');

        ctx.patchState({ error });
        return ctx.dispatch(
          new Navigate([buildUrl(FeaturesRoutingEnum.NotFound)], undefined, { skipLocationChange: true })
        );
      }

      default: {
        console.error(`Code ${error.status} => ${error.statusText}`);
        this.notifService.createToast('Une erreur est survenue', NotifStatus.Failed);
        return ctx.patchState({ error });
      }
    }
    // tslint:enable no-small-switch
  }
}
