import { Notif } from '@commons/notifications/notif';
import { ReplaySubject } from 'rxjs';
var NotificationsService = /** @class */ (function () {
    function NotificationsService() {
        this._notification = new ReplaySubject();
        this._clearToasts = new ReplaySubject();
        this._clearLastToast = new ReplaySubject();
    }
    Object.defineProperty(NotificationsService.prototype, "notification", {
        get: function () {
            return this._notification;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NotificationsService.prototype, "clearToasts", {
        get: function () {
            return this._clearToasts;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NotificationsService.prototype, "clearLastToast", {
        get: function () {
            return this._clearLastToast;
        },
        enumerable: true,
        configurable: true
    });
    NotificationsService.prototype.createToast = function (message, style, duration, callbackConfig) {
        this.notification.next(new Notif({ message: message, style: style, duration: duration, callbackConfig: callbackConfig }));
    };
    NotificationsService.prototype.clear = function () {
        this.clearToasts.next();
    };
    NotificationsService.prototype.clearLast = function () {
        this.clearLastToast.next();
    };
    return NotificationsService;
}());
export { NotificationsService };
