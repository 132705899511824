import { NotifStatus } from '@commons/notifications/notif-status.enum';

export interface INotif {
  message: string;
  style?: NotifStatus;
  duration?: number;
  callbackConfig?: ICallbackConfig;
}

export interface ICallbackConfig {
  text: string;
  callback: Function;
}

export class Notif {
  id: string;
  message: string;
  style?: NotifStatus;
  duration?: number;
  callbackConfig?: ICallbackConfig;

  constructor(data: INotif) {
    this.id = Math.random()
      .toString(36)
      .substr(2, 9);
    this.message = data.message;
    this.style = data.style || NotifStatus.Info;
    this.duration = data.duration;
    this.callbackConfig = data.callbackConfig;
  }
}
