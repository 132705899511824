var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var SessionFailed = /** @class */ (function () {
    function SessionFailed(error) {
        this.error = error;
    }
    return SessionFailed;
}());
export { SessionFailed };
var Init = /** @class */ (function () {
    function Init(tokens) {
        this.tokens = tokens;
    }
    Init.type = '[Session] Init';
    return Init;
}());
export { Init };
var InitUser = /** @class */ (function () {
    function InitUser(id) {
        this.id = id;
    }
    InitUser.type = '[Session] Init User';
    return InitUser;
}());
export { InitUser };
var UpdateUser = /** @class */ (function () {
    function UpdateUser(user) {
        this.user = user;
    }
    UpdateUser.type = '[Session] Update User';
    return UpdateUser;
}());
export { UpdateUser };
var SignIn = /** @class */ (function () {
    function SignIn(username, password) {
        this.username = username;
        this.password = password;
    }
    SignIn.type = '[Session] Sign in';
    return SignIn;
}());
export { SignIn };
var SignInSuccess = /** @class */ (function () {
    function SignInSuccess(tokens) {
        this.tokens = tokens;
    }
    SignInSuccess.type = '[Session] Sign in Success';
    return SignInSuccess;
}());
export { SignInSuccess };
var SignInFailed = /** @class */ (function (_super) {
    __extends(SignInFailed, _super);
    function SignInFailed() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SignInFailed.type = '[Session] Sign in Failed';
    return SignInFailed;
}(SessionFailed));
export { SignInFailed };
var SignUp = /** @class */ (function () {
    function SignUp(firstname, lastname, company, phone, email, password, invitationToken) {
        this.firstname = firstname;
        this.lastname = lastname;
        this.company = company;
        this.phone = phone;
        this.email = email;
        this.password = password;
        this.invitationToken = invitationToken;
    }
    SignUp.type = '[Session] Sign up';
    return SignUp;
}());
export { SignUp };
var SignUpWizbii = /** @class */ (function () {
    function SignUpWizbii(email, firstname, lastname, company, phone, invitationToken) {
        this.email = email;
        this.firstname = firstname;
        this.lastname = lastname;
        this.company = company;
        this.phone = phone;
        this.invitationToken = invitationToken;
    }
    SignUpWizbii.type = '[Session] Sign up Wizbii';
    return SignUpWizbii;
}());
export { SignUpWizbii };
var SignUpFailed = /** @class */ (function (_super) {
    __extends(SignUpFailed, _super);
    function SignUpFailed() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SignUpFailed.type = '[Session] Sign up Failed';
    return SignUpFailed;
}(SessionFailed));
export { SignUpFailed };
var RecoverPassword = /** @class */ (function () {
    function RecoverPassword(email) {
        this.email = email;
    }
    RecoverPassword.type = '[Session] Recover Password';
    return RecoverPassword;
}());
export { RecoverPassword };
var RecoverPasswordSuccess = /** @class */ (function () {
    function RecoverPasswordSuccess() {
    }
    RecoverPasswordSuccess.type = '[Session] Recover Password Success';
    return RecoverPasswordSuccess;
}());
export { RecoverPasswordSuccess };
var RecoverPasswordFailed = /** @class */ (function (_super) {
    __extends(RecoverPasswordFailed, _super);
    function RecoverPasswordFailed() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    RecoverPasswordFailed.type = '[Session] Recover Password Failed';
    return RecoverPasswordFailed;
}(SessionFailed));
export { RecoverPasswordFailed };
var RefreshTokensStore = /** @class */ (function () {
    function RefreshTokensStore(tokens) {
        this.tokens = tokens;
    }
    RefreshTokensStore.type = '[Session] Refresh Tokens Store';
    return RefreshTokensStore;
}());
export { RefreshTokensStore };
var Logout = /** @class */ (function () {
    function Logout() {
    }
    Logout.type = '[Session] Logout';
    return Logout;
}());
export { Logout };
var SetTokens = /** @class */ (function () {
    function SetTokens(tokens) {
        this.tokens = tokens;
    }
    SetTokens.type = '[Session] Set Tokens';
    return SetTokens;
}());
export { SetTokens };
var RemoveTokens = /** @class */ (function () {
    function RemoveTokens() {
    }
    RemoveTokens.type = '[Session] Remove Tokens';
    return RemoveTokens;
}());
export { RemoveTokens };
