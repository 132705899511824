var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { ScrollLockService } from '@core/services/scroll-lock.service';
import { Action, State } from '@ngxs/store';
import { Lock, Unlock } from '@store/scroll-lock/scroll-lock.actions';
var ScrollLockState = /** @class */ (function () {
    function ScrollLockState(scrollLockService) {
        this.scrollLockService = scrollLockService;
    }
    ScrollLockState.prototype.lock = function (ctx) {
        var total = ctx.getState() + 1;
        ctx.setState(total);
        if (total === 1) {
            this.scrollLockService.lock();
        }
    };
    ScrollLockState.prototype.unlock = function (ctx) {
        var total = ctx.getState() - 1;
        ctx.setState(total);
        if (total === 0) {
            this.scrollLockService.unlock();
        }
    };
    __decorate([
        Action(Lock),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], ScrollLockState.prototype, "lock", null);
    __decorate([
        Action(Unlock),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], ScrollLockState.prototype, "unlock", null);
    ScrollLockState = __decorate([
        State({
            name: 'totalScrollLocks',
            defaults: 0,
        }),
        __metadata("design:paramtypes", [ScrollLockService])
    ], ScrollLockState);
    return ScrollLockState;
}());
export { ScrollLockState };
