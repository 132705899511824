<div class="notifications" *ngIf="notifications?.length !== 0" [@animateToasts]>
  <div
    class="notification"
    *ngFor="let notification of notifications; let i = index; trackBy: trackById"
    [ngClass]="'notification--' + notification.style"
    [@animateToasts]
  >
    <div class="notification__bar" [style.animation-duration]="(notification.duration || duration) + 'ms'"></div>
    <svg-icon
      class="notification__close"
      icon="exp-cross"
      height=".6em"
      width=".6em"
      (click)="removeNotification(i)"
    ></svg-icon>
    <div class="notification-message">
      <div class="notification-message__picto" *ngIf="notification.style">
        <svg-icon *ngIf="notification.style === NotifStatus.Success" icon="exp-valid" width="1.5em"></svg-icon>
        <svg-icon *ngIf="notification.style === NotifStatus.Failed" icon="exp-alert" width="1.5em"></svg-icon>
      </div>
      <div class="notification-message__content">
        <p [innerHTML]="notification.message"></p>
      </div>
    </div>
    <button
      *ngIf="notification.callbackConfig"
      class="btn-clean notification__button"
      (click)="notification.callbackConfig.callback()"
    >
      {{ notification.callbackConfig.text }}
    </button>
  </div>
</div>
