var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { HttpClient } from '@angular/common/http';
import { toCandidate } from '@ats/models';
import { environment } from '@env/environment';
import { buildUrl } from '@wizbii/angular-utilities';
import { map } from 'rxjs/operators';
var CandidateWebservice = /** @class */ (function () {
    function CandidateWebservice(http) {
        this.http = http;
        this.countUrl = environment.api.candidate + "/v1/candidates/count-by-job-ids-and-statuses";
    }
    CandidateWebservice.prototype.get = function (id) {
        var url = buildUrl(environment.api.candidate + "/v1/candidates/:id", { id: id });
        return this.http.get(url).pipe(map(toCandidate));
    };
    CandidateWebservice.prototype.getCountCandidatesStatus = function (companyId, statuses) {
        var url = buildUrl(this.countUrl, { companyId: companyId, statuses: statuses ? statuses.join() : undefined });
        return this.http.get(url).pipe(map(function (results) { return results.results; }));
    };
    CandidateWebservice.prototype.findCandidates = function (jobId) {
        return this.http.get(buildUrl(environment.api.candidate + "/v1/candidates", { jobId: jobId })).pipe(map(function (response) { return response.results; }), map(function (applications) { return applications.map(toCandidate); }));
    };
    CandidateWebservice.prototype.patch = function (form, candidate) {
        var url = buildUrl(environment.api.candidate + "/v1/candidates/:id", { id: candidate.id });
        var candidateUpdated = this.formToResource(form, candidate);
        return this.http
            .patch(url, candidateUpdated, {
            headers: { ignoreLoadingBar: '' },
        })
            .pipe(map(function () { return candidateUpdated; }), map(toCandidate));
    };
    CandidateWebservice.prototype.formToResource = function (form, candidate) {
        return !candidate
            ? toCandidate({
                firstName: form.value.firstname,
                lastName: form.value.name,
                email: form.value.email,
                phone: form.value.phone === '' ? null : form.value.phone,
                location: form.value.city === '' ? null : form.value.city,
                currentJob: form.value.job === '' ? null : form.value.job,
            })
            : toCandidate(__assign({}, candidate, { firstName: form.value.firstname, lastName: form.value.name, email: form.value.email, phone: form.value.phone === '' ? null : form.value.phone, location: form.value.city === '' ? null : form.value.city, currentJob: form.value.job === '' ? null : form.value.job }));
    };
    return CandidateWebservice;
}());
export { CandidateWebservice };
