import { PopupComponent } from '@commons/popup/popup/popup.component';
var PopupTriggerDirective = /** @class */ (function () {
    function PopupTriggerDirective(parent) {
        this.parent = parent;
    }
    Object.defineProperty(PopupTriggerDirective.prototype, "id", {
        get: function () {
            return this.parent.id + "__trigger";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PopupTriggerDirective.prototype, "menuId", {
        get: function () {
            return this.parent.id + "__menu";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PopupTriggerDirective.prototype, "ariaExpanded", {
        get: function () {
            return this.parent.opened ? 'true' : 'false';
        },
        enumerable: true,
        configurable: true
    });
    PopupTriggerDirective.prototype.onClick = function () {
        this.parent.toggle();
    };
    return PopupTriggerDirective;
}());
export { PopupTriggerDirective };
