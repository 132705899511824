import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Company, Recruiter } from '@ats/models';
import { FeaturesRoutingEnum } from '@features/features-routing.enum';

@Component({
  selector: 'app-sticky-menu',
  templateUrl: './sticky-menu.component.html',
  styleUrls: ['./sticky-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StickyMenuComponent {
  @Input() recruiter: Recruiter;
  @Input() company: Company;

  featuresRoutingEnum = FeaturesRoutingEnum;
}
