export interface Consent {
  key: string;
  value: boolean;
  label: string;
}

type Consents = Record<string, Consent[]>;

export class SetConsents {
  static readonly type = '[SetConsents] Set Consents';
  constructor(public consents: Consents) {}
}

export class SetConsent {
  static readonly type = '[SetConsent] Set Consent';
  constructor(public consent: Consent) {}
}
