import { Injectable } from '@angular/core';
import { ICallbackConfig, Notif } from '@commons/notifications/notif';
import { NotifStatus } from '@commons/notifications/notif-status.enum';
import { ReplaySubject } from 'rxjs';

@Injectable()
export class NotificationsService {
  private readonly _notification: ReplaySubject<Notif> = new ReplaySubject<Notif>();

  private readonly _clearToasts: ReplaySubject<void> = new ReplaySubject<void>();

  private readonly _clearLastToast: ReplaySubject<void> = new ReplaySubject<void>();

  get notification(): ReplaySubject<Notif> {
    return this._notification;
  }

  get clearToasts(): ReplaySubject<void> {
    return this._clearToasts;
  }

  get clearLastToast(): ReplaySubject<void> {
    return this._clearLastToast;
  }

  createToast(message: string, style?: NotifStatus, duration?: number, callbackConfig?: ICallbackConfig) {
    this.notification.next(new Notif({ message, style, duration, callbackConfig }));
  }

  clear() {
    this.clearToasts.next();
  }

  clearLast() {
    this.clearLastToast.next();
  }
}
