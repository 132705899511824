import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable()
export class ScrollLockService {
  static LOCK_CLASS = 'lock';

  constructor(@Inject(DOCUMENT) private readonly document: any) {}

  lock(): void {
    this.document.documentElement.classList.add(ScrollLockService.LOCK_CLASS);
  }

  unlock(): void {
    this.document.documentElement.classList.remove(ScrollLockService.LOCK_CLASS);
  }
}
