/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./logo.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./logo.component";
import * as i3 from "@wizbii/angular-utilities";
var styles_LogoComponent = [i0.styles];
var RenderType_LogoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LogoComponent, data: {} });
export { RenderType_LogoComponent as RenderType_LogoComponent };
export function View_LogoComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵted(0, null, ["", "\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.text; _ck(_v, 0, 0, currVal_0); }); }
export function View_LogoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-logo", [], [[1, "aria-hidden", 0], [4, "backgroundColor", null], [4, "color", null], [4, "backgroundImage", null], [2, "fallback", null]], null, null, View_LogoComponent_0, RenderType_LogoComponent)), i1.ɵdid(1, 49152, null, 0, i2.LogoComponent, [i3.WINDOW, i3.ImageService], null, null)], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).ariaHidden; var currVal_1 = i1.ɵnov(_v, 1).backgroundColor; var currVal_2 = i1.ɵnov(_v, 1).color; var currVal_3 = i1.ɵnov(_v, 1).backgroundImage; var currVal_4 = i1.ɵnov(_v, 1).fallback; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }); }
var LogoComponentNgFactory = i1.ɵccf("app-logo", i2.LogoComponent, View_LogoComponent_Host_0, { background: "background", size: "size", color: "color", item: "item" }, {}, []);
export { LogoComponentNgFactory as LogoComponentNgFactory };
