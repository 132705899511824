import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Application, Candidate, toApplication, toCandidate, toSource, toUtm } from '@ats/models';
import { environment } from '@env/environment';
import { CandidateApplicationResourceInterface } from '@models/candidate-application/candidate-application.resource';
import { Store } from '@ngxs/store';
import { ApplicationsState } from '@store/applications/applications.state';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class CandidateApplicationWebservice {
  private readonly baseUrl = `${environment.api.candidate}/v1`;

  constructor(private readonly http: HttpClient, private readonly store: Store) {}

  create(form: FormGroup, jobId: string): Observable<{ application: Application; candidate: Candidate }> {
    const resource = this.formToResource(form, jobId);

    const url = `${this.baseUrl}/candidate/application`;

    return this.http.post<any>(url, resource, { headers: { ignoreLoadingBar: '' } }).pipe(
      map(response => ({
        application: toApplication(response.application),
        candidate: toCandidate(response.candidate),
      }))
    );
  }

  private formToResource(form: FormGroup, jobId: string): CandidateApplicationResourceInterface {
    const source = this.store.selectSnapshot(ApplicationsState.sourcePlatform(form.value.source));

    return {
      application: toApplication({
        jobId,
        coverLetter: form.value.presentation,
        resumeFileId: form.value.cv === '' ? null : form.value.cv.id,
        onlineResume: form.value.onlineResume,
        status: form.value.status,
        utmSource: toSource({
          platform: 'App',
          utm: toUtm({
            campaign: jobId,
            content: source ? source.utm.content || null : null,
            medium: source ? source.utm.medium || null : null,
            source: source ? source.name : null,
            term: source ? source.utm.term || null : null,
          }),
        }),
      }),
      candidate: toCandidate({
        firstName: form.value.firstname,
        lastName: form.value.name,
        email: form.value.email,
        phone: form.value.phone === '' ? null : form.value.phone,
        location: form.value.city === '' ? null : form.value.city,
        currentJob: form.value.job === '' ? null : form.value.job,
      }),
    };
  }
}
