import { EventTrackerService } from '@commons/tracker/event-tracker/event-tracker.service';
var EventTrackerDirective = /** @class */ (function () {
    function EventTrackerDirective(eventWebservice) {
        this.eventWebservice = eventWebservice;
        this.model = {
            eventCategory: undefined,
            eventAction: undefined,
            eventLabel: undefined,
            eventValue: undefined,
        };
    }
    Object.defineProperty(EventTrackerDirective.prototype, "eventCategory", {
        set: function (eventCategory) {
            this.model.eventCategory = eventCategory;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EventTrackerDirective.prototype, "eventAction", {
        set: function (eventAction) {
            this.model.eventAction = eventAction;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EventTrackerDirective.prototype, "eventLabel", {
        set: function (eventLabel) {
            this.model.eventLabel = eventLabel;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EventTrackerDirective.prototype, "eventValue", {
        set: function (eventValue) {
            this.model.eventValue = eventValue;
        },
        enumerable: true,
        configurable: true
    });
    EventTrackerDirective.prototype.doClick = function () {
        this.eventWebservice.send(this.model);
    };
    return EventTrackerDirective;
}());
export { EventTrackerDirective };
